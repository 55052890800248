import { DatePicker, DatePickerProps } from "antd";
import { FunctionComponent } from "react";
import dayjs from "../../common/dayjs";

type InputDateProps = DatePickerProps & {
  value?: string;
  onChange?: (value?: string) => void;
};

const InputDate: FunctionComponent<InputDateProps> = ({
  value,
  onChange,
  ...props
}) => {
  return (
    <DatePicker
      value={value ? dayjs(value) : undefined}
      onChange={(date, dateString) => onChange?.(date?.format())}
      {...props}
    />
  );
};

export default InputDate;
