import { useCallback, useMemo, useState } from "react";

const minZoomLevel = 1;
const maxZoomLevel = 200;

export default function useZoom() {
    const [zoom, setZoom] = useState(1);
    const zoomRatio = useMemo(() => zoom / 100, [zoom]);
    const zoomIn = useCallback((pt: number) => {
        setZoom(zoom => Math.floor(
            Math.max(minZoomLevel, Math.min(maxZoomLevel, zoom + pt))
        ));
    }, []);

    return {
        zoom,
        zoomRatio,
        zoomIn,
    }
}