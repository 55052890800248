import { Prisma } from ".prisma/client";
import jsonToFormData from '@ajoelp/json-to-formdata';
import PlusOutlined from "@ant-design/icons/PlusOutlined";
import {
  Alert,
  Button,
  Form,
  Input,
  Space,
  Spin,
  Typography,
  Upload
} from "antd";
import { RcFile, UploadFile } from "antd/es/upload";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AsyncSelect from "./async-select";

type ProjectArea = Prisma.ProjectAreaGetPayload<{
  include: { groups: true; admins: true, projects: true };
}>;
interface ProjectAreaFormProps {
  value?: Partial<ProjectArea>;
  onChange?: (value: Partial<ProjectArea>) => void;
  busy?: boolean;
  error?: string;
  title: string;
  additionalButtons?: React.ReactNode;
}

const ProjectAreaForm: FunctionComponent<ProjectAreaFormProps> = ({
  value,
  onChange,
  busy,
  error,
  title,
  additionalButtons,
}) => {
  const [file, setFile] = useState<File | null>(null);
  const [fileWidth, setFileWidth] = useState<number | null>(null);
  const [fileHeight, setFileHeight] = useState<number | null>(null);
  const [map, setMap] = useState<UploadFile | null>(null);


  const onFileSelect = async (file: RcFile) => {
    // convert file to base64 string
    const reader = new FileReader();
    reader.readAsDataURL(file);
    const img = new Image();
    img.onload = () => {
      setFileWidth(img.naturalWidth);
      setFileHeight(img.naturalHeight);
    };

    reader.onload = () => {
      img.src = reader.result as string;

      setMap({
        name: file.name,
        status: "done",
        url: reader.result as string,
        uid: "-1",
      });
    };
    reader.onerror = (error) => {
      console.log("Error: ", error);
    };

    // convert file to file object
    setFile(file);

    return false;
  };

  const triggerChange = useCallback(
    ({ project_map, ...value }: any) => {
      if (file) {
        value = jsonToFormData(value);
        value.append("project_map", file);
        value.append("map_width", fileWidth);
        value.append("map_height", fileHeight);
      }
      if (onChange) {
        onChange(value);
      }
    },
    [onChange, file, fileWidth, fileHeight]
  );

  useEffect(() => {
    if (value?.project_map) {
      setMap({
        name: value.project_map,
        status: "done",
        url: value.project_map,
        uid: "-1",
      });
    }
  }, [value?.project_map]);

  return (
    <Form
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 14 }}
      layout="horizontal"
      onFinish={triggerChange}
      initialValues={value}
      colon={false}
    >
      <Form.Item label=" ">
        <Typography.Title level={3}>{title}</Typography.Title>
      </Form.Item>
      {error && (
        <Form.Item label=" ">
          <Alert type="error" message={error} />
        </Form.Item>
      )}
      <Form.Item
        label="Name"
        name="name"
        rules={[{ required: true, message: "Please input project area name!" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Area Map"
        name="project_map"
        rules={[{ required: true, message: "Select an image to upload" }]}
      >
        <Upload
          accept="image/*"
          beforeUpload={onFileSelect}
          listType="picture-card"
          maxCount={1}
          fileList={map ? [map] : []}
        >
          <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>{map ? "Change" : "Select"}</div>
          </div>
        </Upload>
      </Form.Item>
      <Form.Item
        label="User Groups"
        name="groups"
      >
        <AsyncSelect
          getOptionLabel={(option) => <Link to={`/user-groups/${option.id}`}>{option.name}</Link> as any}
          getOptionValue={(option) => option.id}
          url={`/user-groups`}
          style={{
            menu: (provided) => ({
              ...provided,
              zIndex: 9999,
            } as any),
          }}
          isMulti
          bottomAction={<Link to="/user-groups/create">Create Group</Link>}
        />
      </Form.Item>

      <Form.Item
        label="Admins"
        name="admins"
      >
        <AsyncSelect
          getOptionLabel={(option) => <Link to={`/users/${option.id}`}>{`${option.first_name} ${option.last_name} (${option.email})`}</Link> as any}
          getOptionValue={(option) => option.id}
          url={`/users`}
          style={{
            menu: (provided) => ({
              ...provided,
              zIndex: 9999,
            } as any),
          }}
          isMulti
          bottomAction={<Link to="/users/create">Create User</Link>}
        />
      </Form.Item>

      <Form.Item
        label="Projects"
        name="projects"
      >
        <AsyncSelect
          getOptionLabel={(option) => <Link to={`/projects/${option.id}`}>{option.name}</Link> as any}
          getOptionValue={(option) => option.id}
          url={`/projects`}
          style={{
            menu: (provided) => ({
              ...provided,
              zIndex: 9999,
            } as any),
          }}
          isMulti
          bottomAction={<Link to="/projects/create">Create Project</Link>}
        />
      </Form.Item>

      <Form.Item label=" ">
        <Space>
          <Spin spinning={busy}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Spin>
          {additionalButtons}
        </Space>
      </Form.Item>
    </Form>
  );
};

export default ProjectAreaForm;
