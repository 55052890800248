import { useContext, useMemo } from "react";
import AppConfigKey from "../../../common/app-config-key";
import AppContext from "../../context/app-context";
import ColorProp from "./color-prop";

export default function useColor() {
    const { getConfig } = useContext(AppContext);

    const color = useMemo(() => {
        return {
            LaydownArea: getConfig(AppConfigKey.LAYDOWN_AREA_COLOR)?.value!,
            Project: getConfig(AppConfigKey.PROJECT_COLOR)?.value!,
            RoadClosure: getConfig(AppConfigKey.ROAD_CLOSURE_COLOR)?.value!,
            ProjectPin: getConfig(AppConfigKey.PIN_COLOR)?.value!,
        } as ColorProp;
    }, [getConfig]);

    return color;
}