import { Project } from ".prisma/client";
import { message } from "antd";
import { FunctionComponent, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProjectForm from "../../components/project-form";
import httpClient from "../../libs/http-client";

interface ProjectCreatePageProps { }

const ProjectCreatePage: FunctionComponent<ProjectCreatePageProps> = () => {
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const navigate = useNavigate();
  const onFinish = async (values: any) => {
    setSaving(true);
    try {
      const response = await httpClient.post<Project>("/projects", values);
      if (response.success && response.data) {
        message.success("Project created successfully!");
        navigate(`/projects/${response.data.id}`);
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      message.error(error.message ?? "Failed to create for unknown reason!");
    }
    setSaving(false);
  };
  return (
    <ProjectForm
      title="Create Project"
      additionalButtons={[]}
      onChange={onFinish}
      error={error}
      busy={saving}
    />
  );
};

export default ProjectCreatePage;
