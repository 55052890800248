import { Prisma } from ".prisma/client";
import { ColumnType } from "antd/es/table";
import { FunctionComponent, useCallback, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import replaceParams from "../../../common/replace-params";
import { formatDate, joinPath } from "../../../common/utility";
import DeleteBtn from "../../components/delete-btn";
import InfiniteTable from "../../components/infinite-table";
import usePaginator from "../../hooks/use-paginator";
import { paginator } from "../../libs/paginator";

interface EmailTemplateListPageProps { }

type EmailTemplate = Prisma.EmailTemplateGetPayload<{}>;

const EmailTemplateListPage: FunctionComponent<EmailTemplateListPageProps> = () => {
  const { load, loading, data, done, reset, updateData } = usePaginator<EmailTemplate>();

  const reload = useCallback((query?: string) => {
    const request = paginator<EmailTemplate>("email-templates", { query } as any, 50);
    reset(request);
  }, []);

  useEffect(() => {
    reload();
  }, []);

  const columns = useMemo(() => {
    return [
      {
        key: "name",
        title: "Name",
        render(value, row, index) {
          return (
            <Link to={replaceParams("/email-templates/:id", { id: row.id })}>
              {row.name}
            </Link>
          );
        },
      },
      {
        key: "subject",
        title: "Subject"
      },
      {
        key: "created_at",
        title: "Created At",
        render(value, row) {
          return formatDate(row.created_at, "LLL");
        },
      },
      {
        key: "actions",
        title: "#",
        render(value, row) {
          return <DeleteBtn
            key="delete"
            apiURL={joinPath("/email-templates", row.id)}
            onDelete={() => {
              updateData((list) =>
                list.filter((item) => item.id !== row.id)
              );
            }}
          />
        },
      },
    ] as ColumnType<EmailTemplate>[];
  }, []);
  return (
    <InfiniteTable
      loading={loading}
      columns={columns}
      hasMore={!done}
      loadMore={load}
      id={"id"}
      key={"id"}
      rowKey={"id"}
      pagination={false}
      dataSource={data}
      title="Email Templates"
      onSearch={reload}
    />
  );
};

export default EmailTemplateListPage;
