import { UserGroup } from ".prisma/client";
import {
  Alert,
  Button,
  Form,
  Input,
  Space,
  Spin,
  Typography
} from "antd";
import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import AsyncSelect from "./async-select";

interface UserGroupFormProps {
  value?: Partial<UserGroup>;
  onChange?: (value: Partial<UserGroup>) => void;
  busy?: boolean;
  error?: string;
  title: string;
  additionalButtons?: React.ReactNode;
}

const UserGroupForm: FunctionComponent<UserGroupFormProps> = ({
  value,
  onChange,
  busy,
  error,
  title,
  additionalButtons,
}) => {
  return (
    <Form
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 14 }}
      layout="horizontal"
      onFinish={onChange}
      initialValues={value}
      colon={false}
    >
      <Form.Item label=" ">
        <Typography.Title level={3}>{title}</Typography.Title>
      </Form.Item>
      {error && (
        <Form.Item label=" ">
          <Alert type="error" message={error} />
        </Form.Item>
      )}
      <Form.Item label="Group Name" name="name" required>
        <Input />
      </Form.Item>

      <Form.Item
        label="Members"
        name="users"
      >
        <AsyncSelect
          getOptionLabel={(option) => <Link to={`/users/${option.id}`}>{option.first_name} {option.last_name} ({option.email})</Link> as any}
          getOptionValue={(option) => option.id}
          url={`/users`}
          isMulti
          bottomAction={<Link to="/users/create">Create User</Link>}
        />
      </Form.Item>
      <Form.Item
        label="Project Areas"
        name="project_areas"
      >
        <AsyncSelect
          getOptionLabel={(option) => <Link to={`/project-areas/${option.id}`}>{option.name}</Link> as any}
          getOptionValue={(option) => option.id}
          url={`/project-areas`}
          isMulti
          bottomAction={<Link to="/project-areas/create">Create Project Area</Link>}
        />
      </Form.Item>

      <Form.Item label="Default Email Template" name="default_email_template">
        <AsyncSelect
          getOptionLabel={(option) => <Link to={`/email-templates/${option.id}`}>{option.name}</Link> as any}
          getOptionValue={(option) => option.id}
          url={`/email-templates`}
          bottomAction={<Link to="/email-templates/create">Create Email Template</Link>}
        />
      </Form.Item>
      <Form.Item label=" ">
        <Space>
          <Spin spinning={busy}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Spin>
          {additionalButtons}
        </Space>
      </Form.Item>
    </Form>
  );
};

export default UserGroupForm;
