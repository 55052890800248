import { Alert, Button, message, Space, Spin } from "antd";
import Konva from "konva";
import {
  useCallback, useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { Group, Image as KonvaImage, Layer, Stage } from "react-konva";
import { EditorProps } from "./editor-props";
import getColor from "./get-color";
import ObjectType from "./object-type";
import Point from "./point";
import ShapeData from "./shape-data";
import { ShapeRender } from "./shape-render";
import ShapeType from "./shape-type";
import styles from "./style.module.scss";
import useColor from "./use-color";
import useZoom from "./use-zoom";

const samePoint = (p1?: Point, p2?: Point) => {
  if (!(p1 && p2)) return false;
  return p1.x === p2.x && p1.y === p2.y;
};


const getId = () => {
  const now = new Date();
  return now.getTime().toString();
};

export function Editor(props: EditorProps) {
  const color = useColor();
  const [selectedShapeId, setSelectedShapeId] = useState<string>();
  const [newShape, setNewShape] = useState<ShapeData>();
  const [img, setImg] = useState<HTMLImageElement>();
  const [loading, setLoading] = useState(false);
  const stageRef = useRef<Konva.Stage>();
  const imageRef = useRef<Konva.Image>();
  const wrapperRef = useRef<HTMLDivElement>();
  const mouseDown = useRef(false);
  const dragStartPosition = useRef<Point>();
  const lastPosition = useRef<Point>();
  const {
    zoomRatio,
    zoomIn
  } = useZoom();
  const shapes = useMemo(() => {
    if (props.value && typeof props.value === "string") {
      const parsed = JSON.parse(props.value);
      if (Array.isArray(parsed)) {
        return parsed as ShapeData[];
      }
    }
    return [] as ShapeData[];
  }, [props.value]);

  const ratio = useMemo(() => {
    if (props.width && props.height) {
      return props.width / props.height;
    }
    return 1;
  }, [props.width, props.height]);

  /**
   * Image changes
   */
  useEffect(() => {
    let mounted = true;

    if (props.src) {
      setImg(undefined);
      setLoading(true);
      const img = new window.Image();
      img.onload = () => {
        if (mounted) {
          const wrapperRect = wrapperRef.current?.getBoundingClientRect();
          const wrapperWidth = wrapperRect?.width || 0;
          const wrapperHeight = wrapperRect?.height || 0;
          const imgWidth = img.naturalWidth;
          const imgHeight = img.naturalHeight;
          const scale =
            Math.min(wrapperWidth / imgWidth, wrapperHeight / imgHeight) * 100;
          zoomIn(Math.floor(scale));
          setImg(img);
        }
        setLoading(false);
      };
      img.onerror = () => {
        setLoading(false);
        message.error("Failed to load image");
      };
      img.src = props.src;
    }
    const onMouseWheelWithCtrl = (e: WheelEvent) => {
      if (e.ctrlKey) {
        e.preventDefault();
        const delta = e.deltaY / 100;
        zoomIn(delta);
      }
    };
    wrapperRef.current?.addEventListener("wheel", onMouseWheelWithCtrl);
    return () => {
      wrapperRef.current?.removeEventListener("wheel", onMouseWheelWithCtrl);
      mounted = false;
    };
  }, [props.src, zoomIn]);

  const initDraw = useCallback(
    (type: ShapeType) => {
      const id = getId();
      const newShape: Partial<ShapeData> = {
        objectId: props.objectId,
        objectType: props.objectType,
        id,
        type,
        points: [],

      };
      setNewShape(newShape as ShapeData);
    },
    [props.objectId, props.objectType]
  );

  const finishDraw = useCallback(
    (shape: ShapeData) => {
      setNewShape(undefined);
      if (props.onChange) {
        props.onChange(JSON.stringify([...shapes, shape]));
      }
      setSelectedShapeId(shape.id);
    },
    [props.onChange, shapes]
  );

  const lastShapeType = useRef<ShapeType>();

  const onDrawButtonClick = useCallback(
    (type: ShapeType) => {
      if (newShape) {
        finishDraw(newShape);
      }
      if (lastShapeType.current === type) {
        lastShapeType.current = undefined;
      } else {
        lastShapeType.current = type;
        initDraw(type);
      }
    },
    [newShape, initDraw, finishDraw]
  );

  const pointerMoved = useCallback(() => {
    const a = dragStartPosition.current;
    const b = lastPosition.current;
    return !samePoint(a, b);
  }, []);

  const getPointerPosition = useCallback(() => {
    const pos = stageRef.current?.getRelativePointerPosition()!;
    return {
      x: zoomRatio ? pos.x / zoomRatio : 0,
      y: zoomRatio ? pos.y / zoomRatio : 0,
    };
  }, [zoomRatio]);

  const handleMouseDown = useCallback(
    (e: Konva.KonvaEventObject<MouseEvent>) => {
      mouseDown.current = true;
      dragStartPosition.current = getPointerPosition();
      lastPosition.current = getPointerPosition();
    },
    [getPointerPosition]
  );

  const onDrag = useCallback(
    (start: Point, current: Point) => {
      if (newShape) {
        switch (newShape.type) {
          case ShapeType.Polygon:
            setNewShape({
              ...newShape,
              points: [
                // four corners of rectangle
                {
                  x: start.x,
                  y: start.y,
                },
                {
                  x: current.x,
                  y: start.y,
                },
                {
                  x: current.x,
                  y: current.y,
                },
                {
                  x: start.x,
                  y: current.y,
                },
              ],
            });
            break;
          case ShapeType.Pin:
            setNewShape({
              ...newShape,
              position: current
            });
            break;
        }
      }
    },
    [newShape?.id, newShape?.type]
  );

  const onDragEnd = useCallback(
    (start: Point, current: Point) => {
      setNewShape(undefined);
      if (newShape) {
        if (props.onChange) {
          props.onChange(JSON.stringify([...shapes, newShape]));
        }
        setSelectedShapeId(newShape.id);
        ignoreNextClick.current = true;
      }
      lastShapeType.current = undefined;
    },
    [newShape, props.onChange, shapes]
  );

  const handleMouseMove = useCallback(
    (e: Konva.KonvaEventObject<MouseEvent>) => {
      if (mouseDown.current) {
        const point = getPointerPosition();
        lastPosition.current = point;
        if (pointerMoved()) {
          onDrag(dragStartPosition.current!, point);
        }
      }
    },
    [getPointerPosition, onDrag]
  );

  const handleMouseUp = useCallback(
    (e: Konva.KonvaEventObject<MouseEvent>) => {
      if (mouseDown.current && pointerMoved()) {
        const point = getPointerPosition();
        onDragEnd(dragStartPosition.current!, point);
      }
      mouseDown.current = false;
    },
    [onDragEnd]
  );

  const ignoreNextClick = useRef(false);

  const onClick = useCallback(
    (e: Konva.KonvaEventObject<MouseEvent>) => {
      setSelectedShapeId(undefined);
      if (ignoreNextClick.current) {
        ignoreNextClick.current = false;
        return;
      }
      if (!newShape) return;
      const point = getPointerPosition();
      if (newShape.type === ShapeType.Polygon) {
        if (!newShape.points) newShape.points = [];
        newShape.points.push(point);
      }

      if (newShape.type === ShapeType.Pin) {
        newShape.position = point;
      }

      setNewShape({ ...newShape });
    },
    [newShape]
  );

  const selectShape = useCallback(
    (shapeId: string) => {
      if (shapeId === selectedShapeId) {
        setSelectedShapeId(undefined);
      } else {
        setSelectedShapeId(shapeId);
      }
    },
    [selectedShapeId, shapes]
  );
  const onShapeDrag = useCallback(
    (node: Konva.Shape, shapeId: string) => {
      const newPosition = node.getAbsolutePosition();

      const newShapes: ShapeData[] = shapes.map((s) => {
        if (s.id === shapeId) {
          return {
            ...s,
            position: {
              x: newPosition.x / zoomRatio,
              y: newPosition.y / zoomRatio,
            },

          };
        }
        return s;
      });
      if (props.onChange) {
        props.onChange(JSON.stringify(newShapes));
      }
    },
    [selectedShapeId, shapes, props.onChange, zoomRatio]
  );

  const eraseShape = useCallback(
    (shapeId: string) => {
      const newShapes = shapes.filter((s) => s.id !== shapeId);
      if (props.onChange) {
        props.onChange(
          newShapes.length ? JSON.stringify(newShapes) : undefined
        );
      }
      setSelectedShapeId(undefined);
    },
    [shapes, props.onChange]
  );

  const selectedShape = shapes.find((s) => s.id === selectedShapeId);
  const getShapeCenter = useCallback((data: ShapeData) => {
    const center: Point = {} as Point;
    if (data?.type === ShapeType.Polygon && !!data.points.length) {
      center.x = (data.points.reduce((a, b) => a + b.x, 0) / data.points.length) + (data?.position?.x ?? 0);
      center.y = (data.points.reduce((a, b) => a + b.y, 0) / data.points.length) + (data?.position?.y ?? 0);
    }

    if (data.position && data?.type === ShapeType.Pin) {
      center.x = data.position.x;
      center.y = data.position.y;
    }
    return center;
  }, []);
  if (!props.src) {
    return <Alert message="Select Project Area First" type="info" />;
  }
  return (
    <div
      className={styles.wrapper}
      style={{
        width: "100%",
        paddingBottom: `${(1 / ratio) * 100}%`,
      }}
    >
      <div className={styles.toolbar}>
        <Space wrap>
          <Button
            type={newShape?.type === ShapeType.Polygon ? "primary" : "default"}
            icon={<i className="ti ti-polygon"></i>}
            onClick={() => onDrawButtonClick(ShapeType.Polygon)}
          />
          {
            props.objectType === ObjectType.Project && <Button
              type={newShape?.type === ShapeType.Pin ? "primary" : "default"}
              icon={<i className="ti ti-pinned"></i>}
              onClick={() => onDrawButtonClick(ShapeType.Pin)}
            />
          }
          <Button
            type={selectedShape ? "primary" : "default"}
            icon={<i className="ti ti-trash-x" />}
            onClick={() => eraseShape(selectedShape?.id!)}
            disabled={!selectedShape}
          />
          <Button
            type={"default"}
            icon={<i className="ti ti-zoom-in" />}
            onClick={() => zoomIn(5)}
          />
          <Button
            type={"default"}
            icon={<i className="ti ti-zoom-out" />}
            onClick={() => zoomIn(-5)}
          />
        </Space>
      </div>
      <div className={styles.canvas} ref={wrapperRef as any}>
        {loading && <Spin />}
        {img && (
          <Stage
            width={img.naturalWidth * zoomRatio}
            height={img.naturalHeight * zoomRatio}
            ref={stageRef as any}
            draggable={false}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onClick={onClick}
            onTap={onClick}
          >
            <Layer>
              <KonvaImage
                ref={imageRef as any}
                x={0}
                y={0}
                image={img}
                scale={{ x: zoomRatio, y: zoomRatio }}
              />
              {newShape && !!newShape.points.length && (
                <ShapeRender
                  data={newShape}
                  zoomRatio={zoomRatio}
                  color={getColor(newShape, color)}
                  center={getShapeCenter(newShape)}
                />
              )}
              <Group>
                {shapes.map((shape) => {
                  return (
                    <ShapeRender
                      key={shape.id}
                      data={shape}
                      zoomRatio={zoomRatio}
                      highlighted={selectedShapeId === shape.id}
                      center={getShapeCenter(shape)}
                      onClick={(e) => {
                        if (!newShape) {
                          e.cancelBubble = true;
                          selectShape(shape.id);
                        }
                      }}
                      onDrag={(e) => {
                        if (!newShape) {
                          e.cancelBubble = true;
                          onShapeDrag(e.target as any, shape.id);
                          setSelectedShapeId(shape.id);
                        }
                      }}
                      color={getColor(shape, color)}
                    />
                  );
                })}
              </Group>
            </Layer>
          </Stage>
        )}
      </div>
    </div>
  );
}

export default Editor;
