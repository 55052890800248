import { blue } from "@ant-design/colors";
import LockOutlined from "@ant-design/icons/LockOutlined";
import {
  Alert,
  Button,
  Form,
  Input,
  message,
  Modal,
  Spin,
  Typography,
} from "antd";
import { FunctionComponent, useContext, useState } from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { isEmail } from "../../../common/utility";
import Center from "../../components/center";
import AppContext from "../../context/app-context";
import httpClient from "../../libs/http-client";
import styles from "./page.module.scss";
interface SignInPageProps { }

const SignInPage: FunctionComponent<SignInPageProps> = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const { user, setUser } = useContext(AppContext);
  const [params] = useSearchParams();

  const navigate = useNavigate();

  const onFinish = async (values: any) => {
    setLoading(true);
    try {
      const response = await httpClient.post<typeof user>("/auth/sign-in", values);
      if (!response.success) {
        throw new Error(response.message || "Something went wrong!");
      }
      localStorage.setItem("access_token", response.meta.access_token);
      localStorage.setItem("refresh_token", response.meta.refresh_token);
      message.info("Welcome! You are successfully signed in.");
      setUser(response.data);
      setError("");
    } catch (err: any) {
      setError(err.message || "Something went wrong!");
    }
    setLoading(false);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const forgotPassword = () => {
    Modal.info({
      title: "Forgot Password",
      content: (
        <Typography.Text>
          Please contact your administrator to reset your password.
        </Typography.Text>
      ),
    });
  };
  if (user) {
    return (
      <Navigate
        to={
          params.get("redirect")
            ? (params.get("redirect") as string)
            : "/dashboard"
        }
      />
    );
  }
  return (
    <Center>
      <Form
        id="sign-in-form"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        style={{
          backgroundColor: blue[0],
        }}
        className={styles.form}
      >
        <Form.Item
          wrapperCol={{
            span: 24,
          }}
        >
          <Typography.Title level={2}>Sign In!!</Typography.Title>
        </Form.Item>
        {error && (
          <Form.Item>
            <Alert type="error" message={error} />
          </Form.Item>
        )}
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              async validator(rule, value, callback) {
                if (!isEmail(value)) {
                  throw new Error("Please input a valid email!");
                }
              },
              message: "Please input your email!",
            },
          ]}
        >
          <Input type={"email"} />
        </Form.Item>

        <Form.Item
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password prefix={<LockOutlined />} />
        </Form.Item>
        <Form.Item>
          <Button type="link" htmlType="button" onClick={forgotPassword}>
            Forgot password
          </Button>
        </Form.Item>
        <Form.Item>
          <Spin spinning={loading}>
            <Button type="primary" htmlType="submit" block>
              Submit
            </Button>
          </Spin>
        </Form.Item>
      </Form>
    </Center>
  );
};

export default SignInPage;
