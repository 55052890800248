
import { DownOutlined } from '@ant-design/icons';
import type { EmailTemplate, User } from '@prisma/client';
import type { MenuProps } from 'antd';
import { Dropdown, Tooltip, message } from 'antd';
import React, { FunctionComponent, useCallback, useContext, useEffect, useState } from 'react';
import AppContext from '../context/app-context';
import { getEmailTemplates } from '../libs/get-email-templates';

type EmailButtonProps = {
    selectedEmailTemplateId?: string;
    users: User[];
}

const EmailButton: FunctionComponent<EmailButtonProps> = ({ selectedEmailTemplateId, users }) => {
    const { user: AuthUser } = useContext(AppContext);
    const [templates, setTemplates] = useState<EmailTemplate[]>([]);
    const [loading, setLoading] = useState(true);
    const [selectedId, setSelectedId] = useState(selectedEmailTemplateId);
    const [working, setWorking] = useState(false);
    const handleClick = useCallback(async (e: any) => {
        setWorking(true);
        e.preventDefault();
        e.stopPropagation();

        if (selectedId && selectedId !== '-1') {
            let body = templates.find((template) => template.id === selectedId)?.body ?? '';
            let subject = templates.find((template) => template.id === selectedId)?.subject ?? '';
            // find all the variables in the body, i.e. {{user.name}}
            const pattern = /[^{}]+(?=})/g

            const bodyVariables = body.match(pattern) ?? [];
            const subjectVariables = subject.match(pattern) ?? [];
            const variables = [...bodyVariables, ...subjectVariables];


            const userVariables = variables.filter((variable) => variable.startsWith('user.'));
            const userGroupVariables = variables.filter((variable) => variable.startsWith('userGroup.'));

            const valueMap = new Map<string, string[]>();

            // calculate the value for each variable
            // if the variable starts with user, i.e. {user.name}, then the value is user.name
            // if the variable starts with userGroup, i.e. {userGroup.name}, then the value is userGroup.name
            // multiple values are separated by comma

            const mapValue = (variable: string, obj: any) => {
                const prop = variable.split('.')[1] as keyof typeof obj;
                let value = obj[prop];
                if (value) {
                    // if toString is defined, call it
                    if (value.toString) {
                        value = value.toString();
                    }
                    if (!valueMap.has(variable)) {
                        valueMap.set(variable, []);
                    }
                    valueMap.get(variable)?.push(value as string);
                }
            }

            users.forEach((user) => {
                userVariables.forEach((variable) => mapValue(variable, user));
            });

            // replace the variables with the values

            valueMap.forEach((value, key) => {
                const pattern = new RegExp(`{${key}}`, 'g');
                const replaceWith = value.join(', ');
                body = body.replace(pattern, replaceWith);
                subject = subject.replace(pattern, replaceWith);
            });

            // open the email client
            window.open(`mailto:${users.map((user) => user.email).join(';')}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`);
            message.success('Email client opened!');
        }
        setWorking(false);
    }, [selectedId, templates]);
    const handleMenuClick = (e: any) => {
        setSelectedId(e.key);
    };

    const menuProps: MenuProps = {
        onClick: handleMenuClick,
        selectedKeys: selectedId ? [selectedId] : [],
        mode: 'vertical',
        style: {
            width: 200,
        },
        items: [
            {
                key: '-1',
                label: '-- Select Email Template --',
            },
            ...templates.map((template) => ({
                key: template.id,
                label: template.name,
            }))
        ]
    };

    const buttonLabel = selectedId && selectedId !== '-1' ? templates.find((template) => template.id === selectedId)?.name : 'Select Email Template';

    useEffect(() => {
        if (!AuthUser?.is_super_admin) {
            return;
        }
        let mounted = true;
        setLoading(true);
        getEmailTemplates().then((templates) => {
            if (mounted) {
                setTemplates(templates);
            }
        })
            .catch((error) => {
                message.error('Failed to load email templates!');
            })
            .finally(() => {
                mounted && setLoading(false);
            });
        return () => {
            mounted = false;
        }
    }, [AuthUser]);

    useEffect(() => {
        setSelectedId(selectedEmailTemplateId);
    }, [selectedEmailTemplateId]);

    if (!AuthUser?.is_super_admin) {
        return null;
    }

    return <Dropdown.Button
        menu={menuProps}
        onClick={handleClick}
        disabled={working || users.length === 0}
        loading={working}
        buttonsRender={([leftButton, rightButton]) => [
            <Tooltip title="Click to send email" key="leftButton">
                {
                    React.cloneElement(leftButton as React.ReactElement<any, string>, { disabled: !selectedId }, buttonLabel)
                }
            </Tooltip>,
            React.cloneElement(rightButton as React.ReactElement<any, string>, { loading, icon: <DownOutlined />, key: 'rightButton' }),
        ]}
    >
        {buttonLabel}
    </Dropdown.Button>
};

export default EmailButton;
