import { EmailTemplate } from ".prisma/client";
import {
  Alert,
  Button,
  Form,
  Input,
  Space,
  Spin,
  Typography
} from "antd";
import { FunctionComponent } from "react";

interface EmailTemplateFormProps {
  value?: Partial<EmailTemplate>;
  onChange?: (value: Partial<EmailTemplate>) => void;
  busy?: boolean;
  error?: string;
  title: string;
  additionalButtons?: React.ReactNode;
  showProjectArea?: boolean;
}

const EmailTemplateForm: FunctionComponent<EmailTemplateFormProps> = ({
  value,
  onChange,
  busy,
  error,
  title,
  additionalButtons
}) => {
  return (
    <Form
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 14 }}
      layout="horizontal"
      onFinish={onChange}
      initialValues={value}
      colon={false}
    >
      <Form.Item label=" ">
        <Typography.Title level={3}>{title}</Typography.Title>
      </Form.Item>
      {error && (
        <Form.Item label=" ">
          <Alert type="error" message={error} />
        </Form.Item>
      )}
      <Form.Item label="Template Name" name="name" required>
        <Input />
      </Form.Item>
      <Form.Item label="Subject" name="subject" required>
        <Input />
      </Form.Item>
      <Form.Item label="Body" name="body" required>
        <Input.TextArea rows={10} />
      </Form.Item>
      <Form.Item label=" " name="hints">
        <Alert
          type="info"
          message="You can use the following variables in the subject and body:"
          description={
            <>
              <ul>
                <li>{'{user.first_name}'} - user's first name</li>
                <li>{'{user.last_name}'} - user's last name</li>
                <li>{'{user.email}'} - user's email</li>
                <li>{'{user.password}'} - user's password (hashed)</li>
              </ul>
            </>
          }
        />
      </Form.Item>
      <Form.Item label=" ">
        <Space>
          <Spin spinning={busy}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Spin>
          {additionalButtons}
        </Space>
      </Form.Item>
    </Form>
  );
};

export default EmailTemplateForm;
