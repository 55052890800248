import ColorProp from "./color-prop";
import ObjectType from "./object-type";
import ShapeData from "./shape-data";
import ShapeType from "./shape-type";

function getColor(shape: ShapeData | undefined, color: ColorProp) {
    if (!shape) {
        return color.Project;
    }
    if (shape.objectType === ObjectType.LaydownArea) {
        return color.LaydownArea;
    }
    if (shape.objectType === ObjectType.RoadClosure) {
        return color.RoadClosure;
    }
    if (shape.type === ShapeType.Pin) {
        return color.ProjectPin;
    }
    return color.Project;
}

export default getColor;