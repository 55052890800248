/**
 * format the value as millions of dollars (7th digit) with 3 fixed decimal places, eg, $4.967. If a project is less than 1,000,000, format it with a leading zero, eg, 500,000 becomes 0.500. Please also test that any solution doesn’t cut 8th or more digits, that is, say, $15,000,000 to display as 5.000
 * @param value 
 * @returns 
 */
export default function toMillion(value?: number): string {
    if (value === undefined || value === null) { 
        return '';
    }
    const [integer, decimal] = (value / 1000000).toFixed(3).split('.');
    const commaSeparatedInteger = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return `$${commaSeparatedInteger}.${decimal}`;
}