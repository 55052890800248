import { Prisma } from ".prisma/client";
import { Space } from "antd";
import { ColumnType } from "antd/es/table";
import { FunctionComponent, useCallback, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import replaceParams from "../../../common/replace-params";
import { formatDate, joinPath } from "../../../common/utility";
import DeleteBtn from "../../components/delete-btn";
import InfiniteTable from "../../components/infinite-table";
import usePaginator from "../../hooks/use-paginator";
import { paginator } from "../../libs/paginator";

interface UserGroupListPageProps { }

type UserGroup = Prisma.UserGroupGetPayload<{
  include: {
    _count: {
      select: {
        users: boolean;
      }
    },
  }
}>;

const UserGroupListPage: FunctionComponent<UserGroupListPageProps> = () => {
  const { load, loading, data, done, reset, updateData } = usePaginator<UserGroup>();

  const reload = useCallback((query?: string) => {
    const request = paginator<UserGroup>("user-groups", { query } as any, 50);
    reset(request);
  }, []);

  useEffect(() => {
    reload();
  }, []);

  const columns = useMemo(() => {
    return [
      {
        key: "name",
        title: "Name",
        render(value, row, index) {
          return (
            <Link to={replaceParams("/user-groups/:id", { id: row.id })}>
              {row.name}
            </Link>
          );
        },
      },
      {
        key: "users",
        title: "Users",
        width: 100,
        render(value, row, index) {
          return row._count.users;
        },
      },
      {
        key: "created_at",
        title: "Created At",
        render(value, row) {
          return formatDate(row.created_at, "LLL");
        },
      },
      {
        key: "actions",
        title: "#",
        render(value, row) {
          return (
            <Space>
              <DeleteBtn
                key="delete"
                apiURL={joinPath("/user-groups", row.id)}
                onDelete={() => {
                  updateData((list) =>
                    list.filter((item) => item.id !== row.id)
                  );
                }}
              />
            </Space>
          );
        },
      },
    ] as ColumnType<UserGroup>[];
  }, []);
  return (
    <InfiniteTable
      loading={loading}
      columns={columns}
      hasMore={!done}
      loadMore={load}
      id={"id"}
      key={"id"}
      rowKey={"id"}
      pagination={false}
      dataSource={data}
      title="User Groups"
      onSearch={reload}
    />
  );
};

export default UserGroupListPage;
