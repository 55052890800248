.wrapper {
  position: relative;

  .canvas {
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    height: calc(100% - 40px);
    overflow: auto
  }

  .toolbar {
    position: absolute;
    z-index: 100;
    width: 100%;
    padding: 5px;
    background: rgba($color: #ffffff, $alpha: .8);
    border-bottom: hsl(0, 0%, 80%) 1px solid;

    [class*="ant-badge-status-dot"] {
      width: 20px !important;
      height: 20px !important;
    }

    i {
      font-size: 20px;
      pointer-events: none;
    }

    :global(.ant-typography) {
      font-size: 14px;
      display: inline-flex;
      align-items: center;
    }

    button {
      position: relative;
      width: 32px !important;
      padding-left: 0 !important;
      padding-right: 0 !important;

      [class*="twitter-picker"] {
        position: absolute;
        top: 100%;
        z-index: 100;
      }
    }
  }
}