import httpClient from "./http-client";
const PAGE_SIZE = 10;
type QueryParam = {
  [key: string]: string | number | boolean | null;
};

export async function* paginator<
  T extends {
    id?: string;
    created_at?: any;
  }
>(url: string, query?: QueryParam, pageSize = PAGE_SIZE) {
  let lastId: string | undefined = undefined;
  let lastCreatedAt: string | undefined = undefined;
  let data: T[] = [];
  url = httpClient.prepareUrl(url);

  while (1) {
    const urlObj = new URL(url);
    urlObj.searchParams.set("limit", pageSize.toString());
    if (lastId) {
      urlObj.searchParams.append("lastId", lastId);
    }
    if (lastCreatedAt) {
      urlObj.searchParams.append("lastCreatedAt", lastCreatedAt);
    }

    if (query) {
      Object.keys(query).forEach((key) => {
        if (!(query[key] === null || query[key] === undefined)) {
          urlObj.searchParams.append(key, query[key] as string);
        }
      });
    }

    try {
      const res = await httpClient.get<T[]>(urlObj.toString());
      if (!res.data) {
        res.data = [];
      }
      data = [...data, ...res.data];
      if (res.data.length < pageSize) {
        return data;
      }
      lastId = data[data.length - 1].id;
      lastCreatedAt = data[data.length - 1].created_at;
      yield data;
    } catch (e) {
      return data;
    }
  }
}
