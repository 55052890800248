import { Alert, Button, message, Space, Spin, Typography } from "antd";
import Konva from "konva";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Group, Image as KonvaImage, Layer, Stage } from "react-konva";
import styles from "./style.module.scss";

import Color from "color";
import { Html } from "react-konva-utils";
import { EditorProps } from "./editor-props";
import getColor from "./get-color";
import ObjectType from "./object-type";
import Point from "./point";
import ShapeData, { ShapeObjectPair } from "./shape-data";
import { ShapeRender } from "./shape-render";
import ShapeType from "./shape-type";
import useColor from "./use-color";
import useZoom from "./use-zoom";

/* eslint-disable-next-line */
type ViewerProp = Omit<EditorProps, 'objectType' | 'value' | 'onChange'> & {
  highlightedProjectId?: string;
  resetHighlight: (id?: string) => void;
  shapes: ShapeData[];
  onOpenProject: (projectId: string) => void;
}

const defaultPair: ShapeObjectPair = {
  type: ShapeType.Pin,
  objectType: ObjectType.Project,
}

const pairs: ShapeObjectPair[] = [
  {
    type: ShapeType.Pin,
    objectType: ObjectType.Project,
  },
  {
    type: ShapeType.Polygon,
    objectType: ObjectType.Project,
  },
  {
    type: ShapeType.Polygon,
    objectType: ObjectType.RoadClosure
  },
  {
    type: ShapeType.Polygon,
    objectType: ObjectType.LaydownArea
  }
];


export function Viewer(props: ViewerProp) {
  const [img, setImg] = useState<HTMLImageElement>();
  const [loading, setLoading] = useState(false);
  const [hoveredShape, setHoveredShape] = useState<ShapeData>();
  const [lastCursorPosition, setLastCursorPosition] = useState<Point>();
  const stageRef = useRef<Konva.Stage>();
  const imageRef = useRef<Konva.Image>();
  const wrapperRef = useRef<HTMLDivElement>();
  const [selectedObjectTypes, setSelectedObjectTypes] = useState<ShapeObjectPair[]>([defaultPair]);
  const [showTitle, setShowTitle] = useState(false);
  const {
    zoomRatio,
    zoomIn,

  } = useZoom();
  const color = useColor();
  useEffect(() => {
    if (props.highlightedProjectId) {
      setSelectedObjectTypes(pairs);
    } else {
      setSelectedObjectTypes([defaultPair]);
    }
  }, [props.highlightedProjectId]);

  const shapes = useMemo(() => {
    return props.shapes.filter((s) => {
      if (s.type === ShapeType.Pin && !s.position) {
        return false;
      }
      if (s.type === ShapeType.Polygon && !s.points.length) {
        return false;
      }

      let keep = true;

      if (!!selectedObjectTypes?.length) {
        keep = keep && selectedObjectTypes.some((t) => t.type === s.type && t.objectType === s.objectType);
      }
      if (props.highlightedProjectId) {
        keep = keep && props.highlightedProjectId === s.projectId;
      }
      return keep;
    })
      .sort(
        (a, b) => {
          if (a.type === b.type) {
            return 0;
          }
          if (a.type === ShapeType.Pin) { // Pin is always on top
            return 1;
          }
          return -1;
        }
      )
  }, [props.shapes, props.highlightedProjectId, selectedObjectTypes]);

  const ratio = useMemo(() => {
    if (props.width && props.height) {
      return props.width / props.height;
    }
    return 1;
  }, [props.width, props.height]);

  /**
   * Image changes
   */
  useEffect(() => {
    let mounted = true;

    if (props.src) {
      setImg(undefined);
      setLoading(true);
      const img = new window.Image();
      img.onload = () => {
        if (mounted) {
          const wrapperRect = wrapperRef.current?.getBoundingClientRect();
          const wrapperWidth = wrapperRect?.width || 0;
          const wrapperHeight = wrapperRect?.height || 0;
          const imgWidth = img.naturalWidth;
          const imgHeight = img.naturalHeight;
          const scale =
            Math.min(wrapperWidth / imgWidth, wrapperHeight / imgHeight) * 100;
          zoomIn(Math.floor(scale));
          setImg(img);
        }
        setLoading(false);
      };
      img.onerror = () => {
        setLoading(false);
        message.error("Failed to load image");
      };
      img.src = props.src;
    }
    const onMouseWheelWithCtrl = (e: WheelEvent) => {
      if (e.ctrlKey) {
        e.preventDefault();
        const delta = e.deltaY / 100;
        zoomIn(delta);
      }
    };
    wrapperRef.current?.addEventListener("wheel", onMouseWheelWithCtrl);
    // wrapperRef.current?.addEventListener("mousemove", onMouseMove);
    return () => {
      wrapperRef.current?.removeEventListener("wheel", onMouseWheelWithCtrl);
      // wrapperRef.current?.removeEventListener("mousemove", onMouseMove);
      mounted = false;
    };
  }, [props.src, zoomIn]);

  const highlightedShape = useMemo(() => {
    return shapes.find((s) => s.type === ShapeType.Pin && s.projectId === props.highlightedProjectId);
  }, [props.highlightedProjectId, shapes]);

  const isShapeObjectTypeSelected = useCallback((type: ShapeType, objectType: ObjectType) => {
    if (!selectedObjectTypes) return true;
    return selectedObjectTypes.some(e => e.type === type && e.objectType === objectType);
  }, [selectedObjectTypes]);

  const toggleShapeObject = useCallback((type: ShapeType, objectType: ObjectType) => {


    // get index of the objectType in the selectedObjectTypes array
    const index = selectedObjectTypes?.findIndex(e => e.type === type && e.objectType === objectType) ?? -1;

    // if the objectType is not selected, add it to the list
    let newList = selectedObjectTypes ? [...selectedObjectTypes] : [];
    if (index === -1) {
      newList.push({ type, objectType });
    } else {
      // if the objectType is selected, remove it from the list
      newList = newList.filter((e, i) => i !== index);
    }
    setSelectedObjectTypes(newList);

  }, [selectedObjectTypes])

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!highlightedShape) return;
      document.getElementById(`shape-${highlightedShape.id}`)?.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "center",
      });
    }, 100);
    return () => {
      clearTimeout(timeout);
    }
  }, [highlightedShape]);

  const CheckBox = ({
    selected,
    label,
    color,
    onClick,

  }: { label: string, selected: boolean, color: string, onClick: () => void }) => {
    return <Typography.Text
      style={{
        cursor: 'pointer',
        color
      }}
      onClick={onClick}
    >
      <i className={
        selected ? "fa-solid fa-circle-check" : "fa-regular fa-circle"
      } />
      <span style={{ marginLeft: 5 }}>{label}</span>
    </Typography.Text>
  }

  const getShapeCenter = useCallback((data: ShapeData) => {
    const center: Point = {} as Point;
    if (data?.type === ShapeType.Polygon) {
      center.x = (data.points.reduce((a, b) => a + b.x, 0) / data.points.length) + (data?.position?.x ?? 0);
      center.y = (data.points.reduce((a, b) => a + b.y, 0) / data.points.length) + (data?.position?.y ?? 0);
    }

    if (data?.type === ShapeType.Pin) {
      center.x = data.position.x;
      center.y = data.position.y;
    }
    return center;
  }, []);

  if (!props.src) {
    return <Alert message="Select Project Area First" type="info" />;
  }
  return (
    <div
      className={styles.wrapper}
      style={{
        width: "100%",
        paddingBottom: `${(1 / ratio) * 100}%`,
      }}
    >
      <div className={styles.toolbar}>
        <Space wrap>
          <Button
            type={"default"}
            icon={<i className="ti ti-zoom-in" />}
            onClick={() => zoomIn(5)}
          />
          <Button
            type={"default"}
            icon={<i className="ti ti-zoom-out" />}
            onClick={() => zoomIn(-5)}
          />

          <CheckBox
            selected={isShapeObjectTypeSelected(ShapeType.Pin, ObjectType.Project)}
            label="Project"
            color={color.ProjectPin}
            onClick={() => toggleShapeObject(ShapeType.Pin, ObjectType.Project)}
          />

          <CheckBox
            selected={isShapeObjectTypeSelected(ShapeType.Polygon, ObjectType.RoadClosure)}
            label="Road Closure"
            color={color.RoadClosure}
            onClick={() => toggleShapeObject(ShapeType.Polygon, ObjectType.RoadClosure)}
          />

          <CheckBox
            selected={isShapeObjectTypeSelected(ShapeType.Polygon, ObjectType.LaydownArea)}
            label="Laydown Area"
            color={color.LaydownArea}
            onClick={() => toggleShapeObject(ShapeType.Polygon, ObjectType.LaydownArea)}
          />

          <CheckBox
            selected={isShapeObjectTypeSelected(ShapeType.Polygon, ObjectType.Project)}
            label="Other Project Area"
            color={color.Project}
            onClick={() => toggleShapeObject(ShapeType.Polygon, ObjectType.Project)}
          />

          <CheckBox
            selected={showTitle}
            label={showTitle ? "Hide Label" : "Show Label"}
            color={"#000000"}
            onClick={() => setShowTitle(!showTitle)}
          />
        </Space>

      </div>
      <div className={styles.canvas} ref={wrapperRef as any}

      >
        {loading && <Spin />}
        {img && (
          <Stage
            width={img.naturalWidth * zoomRatio}
            height={img.naturalHeight * zoomRatio}
            ref={stageRef as any}
            draggable={false}
            onMouseMove={(e) => {
              const position = stageRef.current?.getPointerPosition();
              setLastCursorPosition(position ?? undefined);
            }}
          >
            <Layer>
              <Group
              >
                <KonvaImage
                  ref={imageRef as any}
                  x={0}
                  y={0}
                  image={img}
                  scale={{ x: zoomRatio, y: zoomRatio }}
                />
                {shapes.map((shape) => {
                  return (
                    <ShapeRender
                      key={shape.id}
                      data={shape}
                      color={getColor(shape, color)}
                      zoomRatio={zoomRatio}
                      highlighted={hoveredShape?.id === shape.id}
                      showLabel
                      onClick={() => {
                        if (shape.type === ShapeType.Pin) {
                          const id = shape.projectId === props.highlightedProjectId ? undefined : shape.projectId;
                          setSelectedObjectTypes([defaultPair])
                          props.resetHighlight(id!);

                        }
                      }}
                      onDoubleClick={() => {
                        props.onOpenProject(shape.projectId!);
                      }}
                      onMouseOver={() => {
                        setHoveredShape(shape);
                      }}
                      onMouseOut={() => {
                        setHoveredShape(undefined);
                      }}
                      center={getShapeCenter(shape)}
                    />
                  );
                })}


              </Group>
              <Html

              >
                <div
                  style={{
                    display: hoveredShape ? "inline-block" : "none",
                    position: "absolute",
                    background: getColor(hoveredShape, color),
                    color: Color(getColor(hoveredShape, color)).isDark() ? "white" : "black",
                    padding: "0px 10px",
                    top: (lastCursorPosition?.y ?? 0) + 10,
                    left: (lastCursorPosition?.x ?? 0) + 10,
                    whiteSpace: "nowrap",
                    zIndex: 1,
                  }}
                  dangerouslySetInnerHTML={{
                    __html: (hoveredShape?.name ?? '').replaceAll('\n', '<br/>')
                  }}
                />
                {
                  shapes.map((shape) => <div
                    id={`shape-${shape.id}`}
                    key={shape.id}
                    style={{
                      display: 'inline-block',
                      position: "absolute",
                      background: getColor(shape, color),
                      color: Color(getColor(shape, color)).isDark() ? "white" : "black",
                      zIndex: 0,
                      top: ((getShapeCenter(shape).y ?? 0) * zoomRatio),
                      left: ((getShapeCenter(shape).x ?? 0) * zoomRatio),
                      transform: `translate(-50%, -50%)`,
                      whiteSpace: "nowrap",
                      visibility: showTitle ? "visible" : "hidden",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: shape.title ?? ''
                    }}
                    onMouseOver={() => {
                      setHoveredShape(shape);
                    }}
                    onMouseOut={() => {
                      setHoveredShape(undefined);
                    }}
                  />)
                }

              </Html>
            </Layer>
          </Stage>
        )}
      </div>
    </div>
  );
}

export default Viewer;
