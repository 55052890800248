import { useCallback, useEffect, useRef, useState } from "react";
export default function usePaginator<T = any>() {
  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(true);
  const [data, setData] = useState<T[]>([]);

  const isMounted = useRef<true | false>(true);

  const paginatorRef = useRef<AsyncGenerator<T[]>>();

  const load = async () => {
    if (!paginatorRef.current) {
      return;
    }
    setLoading(true);
    const { value, done } = await paginatorRef.current.next();
    if (!isMounted.current) return;
    if (value) {
      setData(value);
    }
    setDone(!!done);
    setLoading(false);
  };

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const reset = useCallback((paginator: AsyncGenerator<T[]>) => {
    paginatorRef.current = paginator;
    load();
  }, []);

  return { data, loading, done, load, reset, updateData: setData };
}
