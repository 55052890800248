import { Prisma, ProjectArea } from ".prisma/client";
import SearchOutlined from "@ant-design/icons/SearchOutlined";
import {
  Alert,
  Col, DatePicker, Drawer,
  Image,
  Input, List, Row,
  Space,
  Spin,
  Typography
} from "antd";
import dayjs, { Dayjs } from "dayjs";
import { FunctionComponent, useEffect, useMemo, useState } from "react";
import { DATE_FORMAT } from "../../common/constants";
import AsyncSelect from "../components/async-select";
import ObjectType from "../components/canvas/object-type";
import ShapeData from "../components/canvas/shape-data";
import Viewer from "../components/canvas/viewer";
import httpClient from "../libs/http-client";
import toMillion from "../libs/to-million";
import styles from "./dashboard.module.scss";

interface DashboardPageProps { }

type ProjectWithChildren = Prisma.ProjectGetPayload<{
  include: {
    laydown_areas: true;
    road_closures: true;
    areas: true;
  };
}>;

type RangeValue = [Dayjs | null, Dayjs | null] | null;



const DashboardPage: FunctionComponent<DashboardPageProps> = () => {
  const [projectArea, setProjectArea] = useState<ProjectArea>();
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState<ProjectWithChildren[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [range, setRange] = useState<RangeValue>(null);
  const [projectDetail, setProjectDetail] = useState<ProjectWithChildren>();
  const [highlightedProjectId, setHighlightedProjectId] = useState<string | null>(null);

  const filteredProjects = useMemo(() => {

    return projects.filter((project) => {
      const name = project.name?.toLowerCase();
      if (searchTerm && !name?.includes(searchTerm.toLowerCase())) {
        return false;
      }

      return true;
    })
  }, [projects, range, searchTerm]);


  const shapes = useMemo(() => {
    let startDate = range?.[0];
    let endDate = range?.[1];
    const output: ShapeData[] = [];
    filteredProjects.forEach((project) => {
      const name = project.name?.toLowerCase();
      if (searchTerm && !name?.includes(searchTerm.toLowerCase())) {
        return;
      }


      const shapes = JSON.parse(project.shape || "[]") as ShapeData[];
      shapes.forEach((shape) => {
        shape.objectType = ObjectType.Project;
        shape.objectId = project.id;
        shape.projectId = project.id;
        shape.name = project.name || "";
        const parts: string[] = [];
        if (project.id_number) {
          parts.push(project.id_number);
        }
        if (project.name) {
          parts.push(`(${project.name})`);
        }
        shape.title = parts.join(" ");

        output.push(shape);
      });
      project.laydown_areas.forEach((laydownArea) => {
        const laydownAreaStartDate = laydownArea.start_date ? dayjs(laydownArea.start_date) : undefined;
        const laydownAreaEndDate = laydownArea.end_date ? dayjs(laydownArea.end_date) : undefined;
        if (startDate) {
          if (laydownAreaEndDate && laydownAreaEndDate.isBefore(startDate)) {
            return;
          }
        }

        if (endDate) {
          if (laydownAreaStartDate && laydownAreaStartDate.isAfter(endDate)) {
            return;
          }
        }
        const shapes = JSON.parse(laydownArea.shape || "[]") as ShapeData[];

        shapes.forEach((shape) => {
          shape.objectType = ObjectType.LaydownArea;
          shape.objectId = laydownArea.id;
          shape.projectId = project.id;
          shape.name = `${laydownArea.name}\n${dayjs(laydownArea.start_date).format(DATE_FORMAT)} - ${dayjs(laydownArea.end_date).format(DATE_FORMAT)}`;
          const parts: string[] = [];
          if (laydownArea.id_number) {
            parts.push(laydownArea.id_number);
          }
          if (laydownArea.name) {
            parts.push(`(${laydownArea.name})`);
          }
          shape.title = parts.join(" ");
          output.push(shape);
        });
      });

      project.road_closures.forEach((roadClosure) => {
        const roadClosureStartDate = roadClosure.start_date ? dayjs(roadClosure.start_date) : undefined;
        const roadClosureEndDate = roadClosure.end_date ? dayjs(roadClosure.end_date) : undefined;
        if (startDate) {
          if (roadClosureEndDate && roadClosureEndDate.isBefore(startDate)) {
            return;
          }
        }

        if (endDate) {
          if (roadClosureStartDate && roadClosureStartDate.isAfter(endDate)) {
            return;
          }
        }
        const shapes = JSON.parse(roadClosure.shape || "[]") as ShapeData[];

        shapes.forEach((shape) => {
          shape.objectType = ObjectType.RoadClosure;
          shape.objectId = roadClosure.id;
          shape.projectId = project.id;
          shape.name = `${roadClosure.name}\n${dayjs(roadClosure.start_date).format(DATE_FORMAT)} - ${dayjs(roadClosure.end_date).format(DATE_FORMAT)}`;
          const parts: string[] = [];
          if (roadClosure.id_number) {
            parts.push(roadClosure.id_number);
          }
          if (roadClosure.name) {
            parts.push(`(${roadClosure.name})`);
          }
          shape.title = parts.join(" ");
          output.push(shape);
        });
      });
    });
    return output;
  }, [filteredProjects, searchTerm, range]);
  useEffect(() => {
    let mounted = true;
    if (projectArea) {
      setLoading(true);
      httpClient
        .get(`/projects/all?project_area_id=${projectArea.id}&active_only=true`)
        .then((response) => {
          if (mounted) {
            setProjects(response.data);
          }
        })
        .finally(() => {
          if (mounted) {
            setLoading(false);
          }
        });
    }
    return () => {
      mounted = false;
    };
  }, [projectArea]);

  return (
    <Space
      direction="vertical"
      style={{
        width: "100%",
      }}
    >
      <Row>
        <Col span={12}>
          <Typography.Title level={3}>{projectArea?.name}</Typography.Title>
        </Col>
        <Col span={12}>
          <AsyncSelect
            placeholder="Select project area"
            value={projectArea}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            url={`/project-areas`}
            style={{
              menu: (provided) => ({
                ...provided,
                zIndex: 9999,
              } as any),
            }}
            onChange={setProjectArea}
            autoSelectFirstOption
          />
        </Col>
      </Row>
      <Row>
        <Col xs={24} md={{
          span: 12,
          offset: 12,
        }}>
          <div className={styles.search}>
            <Input
              addonBefore={<SearchOutlined />}
              placeholder="Search project"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              allowClear
            />
            <DatePicker.RangePicker
              value={range}
              onChange={setRange}
              allowClear
            />
          </div>
        </Col>
      </Row>

      <Spin spinning={loading}>
        <Row>
          <Col
            xs={{
              span: 24,
              order: 2,
            }}
            md={{
              span: 18,
              order: 1,
            }}
            className={styles.wrapper}>

            {!projectArea && (
              <Alert message="Please select a project area" type="info" />
            )}
            {projectArea && (
              <Viewer
                src={projectArea?.project_map}
                width={projectArea.map_width}
                height={projectArea.map_height}
                shapes={shapes}
                onOpenProject={id => setProjectDetail(projects.find(e => e.id === id))}
                highlightedProjectId={highlightedProjectId || undefined}
                resetHighlight={(id) => setHighlightedProjectId(id ?? null)}
              />
            )}
          </Col>
          <Col
            xs={{
              span: 24,
              order: 1,
            }}
            md={{
              span: 6,
              order: 2,
            }}


          >
            <div className={styles.projectList}>
              <Typography.Title level={4}>Projects</Typography.Title>
              <List
                itemLayout="horizontal"
                dataSource={filteredProjects}
                renderItem={(project) => (
                  <List.Item
                    onClick={() => setHighlightedProjectId(project.id === highlightedProjectId ? null : project.id)}
                    onDoubleClick={() => {
                      setProjectDetail(project);
                      setHighlightedProjectId(project.id);
                    }}
                    style={{
                      cursor: "pointer",
                      backgroundColor: project.id === highlightedProjectId || project.id === projectDetail?.id ? "#f0f0f0" : "white",
                    }}
                  >
                    <List.Item.Meta
                      title={project.name}
                    />
                  </List.Item>
                )}
              />
            </div>
          </Col>
        </Row>
      </Spin>
      <Drawer
        open={!!projectDetail}
        onClose={() => {
          setProjectDetail(undefined);
          setHighlightedProjectId(null);
        }}
        width={`calc(100vw - 50px)`}
        contentWrapperStyle={{
          maxWidth: "700px",
        }}
      >
        <Space direction="vertical" style={{ width: "100%", padding: 10 }}>
          <Image
            src={require("../assets/va-logo.png")}
            alt="VA"
            preview={false}
          />
        </Space>
        <table className="dashboard-table">
          <tbody>
            <tr>
              <th colSpan={2} className="center">
                Project Summary (Construction)
              </th>
              <th className="center">Procedure #</th>
              <td className="center">{projectDetail?.procedure}</td>
            </tr>
            <tr>
              <th>Project #</th>
              <td>{projectDetail?.pid}</td>
              <th colSpan={2} className="center">
                Award Date
              </th>
            </tr>
            <tr>
              <th>Project Name</th>
              <td>{projectDetail?.name}</td>
              <td colSpan={2} className="center">
                {projectDetail?.award_date
                  ? dayjs(projectDetail?.award_date).format("MM/DD/YYYY")
                  : null}
              </td>
            </tr>
            <tr>
              <th>SCIP #</th>
              <td>{projectDetail?.scip_id}</td>
              <th colSpan={2} className="center">
                Contract Amount (Mil)
              </th>
            </tr>
            <tr>
              <th>Project By</th>
              <td>{projectDetail?.by}</td>
              <td colSpan={2} className="center">
                {toMillion(projectDetail?.contract_amount as number)}
              </td>
            </tr>
            <tr>
              <th>CO</th>
              <td>{projectDetail?.co}</td>
              <th colSpan={2} className="center">
                Design
              </th>
            </tr>
            <tr>
              <th>COR</th>
              <td>{projectDetail?.cor}</td>
              <th className="center">Start</th>
              <th className="center">End</th>
            </tr>
            <tr>
              <th>Lead/PM</th>
              <td>{projectDetail?.manager}</td>
              <td className="center">
                {projectDetail?.design_start_date
                  ? dayjs(projectDetail?.design_start_date).format(
                    "MM/DD/YYYY"
                  )
                  : null}
              </td>
              <td className="center">
                {projectDetail?.design_end_date
                  ? dayjs(projectDetail?.design_end_date).format(
                    "MM/DD/YYYY"
                  )
                  : null}
              </td>
            </tr>
            <tr>
              <th>Superintendent</th>
              <td>{projectDetail?.superintendent}</td>
              <th colSpan={2} className="center">
                Construction
              </th>
            </tr>
            <tr>
              <th className="center">Contractor</th>
              <td>{projectDetail?.contractor}</td>
              <th className="center">Start</th>
              <th className="center">End</th>
            </tr>
            <tr>
              <th>A\E</th>
              <td>{projectDetail?.ae}</td>
              <td className="center">
                {projectDetail?.construction_start_date
                  ? dayjs(projectDetail?.construction_start_date).format(
                    "MM/DD/YYYY"
                  )
                  : null}
              </td>
              <td className="center">
                {projectDetail?.construction_end_date
                  ? dayjs(projectDetail?.construction_end_date).format(
                    "MM/DD/YYYY"
                  )
                  : null}
              </td>
            </tr>
            <tr>
              <th colSpan={2}>Road Closure</th>
              <th className="center">Start</th>
              <th className="center">End</th>
            </tr>
            {!projectDetail?.road_closures?.length && (
              <tr>
                <td colSpan={2} className="right">
                  &nbsp;
                </td>
                <td colSpan={2} className="center">
                  No road closures
                </td>
              </tr>
            )}
            {projectDetail?.road_closures.map((roadClosure, index) => (
              <tr key={roadClosure.id}>
                <td colSpan={2} className="right">
                  {roadClosure?.name}
                </td>
                <td className="center">
                  {roadClosure?.start_date
                    ? dayjs(roadClosure?.start_date).format("MM/DD/YYYY")
                    : null}
                </td>
                <td className="center">
                  {roadClosure?.end_date
                    ? dayjs(roadClosure?.end_date).format("MM/DD/YYYY")
                    : null}
                </td>
              </tr>
            ))}
            <tr>
              <th colSpan={2}>Laydown Area</th>
              <th className="center">Start</th>
              <th className="center">End</th>
            </tr>
            {!projectDetail?.laydown_areas?.length && (
              <tr>
                <td colSpan={2}>&nbsp;</td>
                <td colSpan={2} className="center">
                  No laydown areas
                </td>
              </tr>
            )}
            {projectDetail?.laydown_areas.map((laydown_area, index) => (
              <tr key={laydown_area.id}>
                <td colSpan={2} className="right">{laydown_area?.name}</td>
                <td className="center">
                  {laydown_area?.start_date
                    ? dayjs(laydown_area?.start_date).format("MM/DD/YYYY")
                    : null}
                </td>
                <td className="center">
                  {laydown_area?.end_date
                    ? dayjs(laydown_area?.end_date).format("MM/DD/YYYY")
                    : null}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Drawer>
    </Space>
  );
};

export default DashboardPage;
