import { AppConfig, Prisma } from ".prisma/client";
import { Modal, Spin, message } from "antd";
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import AppConfigKey from "../../common/app-config-key";
import AuthTokenPayload from "../../common/auth-token-payload";
import httpClient from "../libs/http-client";
import { clearTokens, refreshTokens } from "../libs/token-manager";
import { Router } from '../router';
import Center from "./../components/center";

const IDLE_TIME = 1000 * 60 * 15; // 15 minutes
const MIN_TIME_TO_SHOW_MODAL = 1000 * 60 * 3; // 3 minutes

export enum AppState {
  LOADING = "LOADING",
  LOADED = "LOADED",
}

type User = Prisma.UserGetPayload<{
  include: { user_groups: true };
}>;
type AppContextType = {
  updateConfigs: (list: AppConfig[]) => void;
  getConfig: (key: AppConfigKey) => AppConfig | undefined;
  user?: AuthTokenPayload;
  setUser: (user?: AuthTokenPayload) => void;
  appState?: AppState;
  setAppState: (appState?: AppState) => void;
  logout: () => void;
};

const AppContext = createContext<AppContextType>({} as AppContextType);

type ProviderProps = {
  children: React.ReactNode;
};

export const useAppContext = () => {
  return useContext(AppContext);
};

export const AppContextProvider = ({ children }: ProviderProps) => {
  const [remainingTime, setRemainingTime] = useState(0);

  const [user, setUser] = useState<AuthTokenPayload>();
  const [appConfigs, setAppConfigs] = useState<AppConfig[]>([]);
  const [appState, setAppState] = useState<AppState | undefined>(
    AppState.LOADING
  );
  const getConfig = useCallback((key: AppConfigKey) => {
    return appConfigs?.find(e => e.key === key);
  }, [appConfigs]);

  const logout = useCallback(() => {
    setUser(undefined);
    clearTokens();
    setRemainingTime(0);
    message.info("Goodbye! See you soon!");
    Router.navigate("/sign-in");
  }, []);

  const onIdle = () => {
    logout();
    setRemainingTime(0);
    message.info(
      "You have been logged out due to inactivity. Please sign in again."
    );
    Router.navigate("/sign-in");
  }

  const onActive = () => {
    setRemainingTime(0);
  }

  const timer = useIdleTimer({
    timeout: IDLE_TIME,
    onIdle,
    onActive,
    startManually: true,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime(timer.getRemainingTime() / 1000);
    }, 1000);
    return () => clearInterval(interval);
  }, [timer]);


  useEffect(() => {
    setAppState(AppState.LOADING);
    Promise.all([
      refreshTokens(),
      httpClient.get<AppConfig[]>("/app-configs").catch(() => ({ data: [] })),
    ]).then(([tokenResult, appConfigs]) => {
      if (tokenResult) {
        setUser(tokenResult.payload);
      }
      setAppConfigs(appConfigs.data!);
    }).finally(() => {
      setAppState(AppState.LOADED);
    });
  }, []);

  useEffect(() => {
    if (user) {
      timer.start();
    }
  }, [user]);

  const remainingMin = Math.floor(remainingTime / 60).toString().padStart(2, "0");
  const remainingSec = Math.floor(remainingTime % 60).toString().padStart(2, "0");
  const showIdleModal = timer.getRemainingTime() < MIN_TIME_TO_SHOW_MODAL && timer.getRemainingTime() > 0;
  return (
    <AppContext.Provider
      value={{
        user,
        setUser,
        appState,
        setAppState,
        getConfig,
        updateConfigs: setAppConfigs,
        logout,
      }}
    >
      {appState === AppState.LOADING ? (
        <Center>
          <Spin size="large" tip="Loading..." />
        </Center>
      ) : (
        children
      )}
      <Modal
        open={showIdleModal}
        closable={false}
        centered
        title="Are you still there?"
        okText="Stay logged in"
        cancelText="Log out"
        onCancel={logout}
        onOk={() => {
          timer.reset();
        }}
      >
        <p>
          Looks like you are idle. You will be logged out in{" "} {remainingMin}:{remainingSec} seconds.
        </p>
        <p>
          Click <b>Stay logged in</b> if you wish to stay.
        </p>
      </Modal>
    </AppContext.Provider>
  );
};

export default AppContext;
