.wrapper {
    position: relative;


}

.search {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    justify-content: flex-end;

    [class*="ant-input-group-wrapper"],
    [class*="ant-picker-range"] {
        max-width: 300px;
    }

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 0.5rem;
        justify-content: center;
        align-items: center;

        [class*="ant-input-group-wrapper"],
        [class*="ant-picker-range"] {
            max-width: 100%;
            width: 100%;
        }
    }

}