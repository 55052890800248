import { UserGroupV2 } from ".prisma/client";
import { message } from "antd";
import { FunctionComponent, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserGroupForm from "../../components/user-group-form";
import httpClient from "../../libs/http-client";

interface UserGroupCreatePageProps { }

const UserGroupCreatePage: FunctionComponent<UserGroupCreatePageProps> = () => {
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const navigate = useNavigate();
  const onFinish = async (values: any) => {
    setSaving(true);
    try {
      const response = await httpClient.post<UserGroupV2>("/user-groups", {
        ...values,
        default_email_template_id: values?.default_email_template?.id ?? null
      });
      if (response.success && response.data) {
        message.success("User group created successfully!");
        navigate(`/user-groups/${response.data.id}`);
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      setError(error.message || "Something went wrong!");
    }
    setSaving(false);
  };
  return (
    <UserGroupForm
      title="Create User Group"
      additionalButtons={[]}
      onChange={onFinish}
      error={error}
      busy={saving}
    />
  );
};

export default UserGroupCreatePage;
