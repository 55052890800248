import { Flex } from "antd";
import { FunctionComponent, ReactNode, useCallback, useRef } from "react";
import { StylesConfig } from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import httpClient from "../libs/http-client";

export interface AsyncSelectProps<T = any> {
  options?: T[];
  getOptionLabel: (option: T) => ReactNode;
  getOptionValue: (option: T) => string;
  onChange?: (option: T) => void;
  url?: string;
  query?: { [key: string]: any };
  pageSize?: number | 'all';
  name?: string;
  placeholder?: string;
  disabled?: boolean;
  value?: T;
  style?: StylesConfig;
  autoSelectFirstOption?: boolean;
  isMulti?: boolean;
  bottomAction?: ReactNode;
}

const AsyncSelect: FunctionComponent<AsyncSelectProps> = ({
  getOptionLabel,
  getOptionValue,
  onChange,
  url,
  query,
  pageSize = 10,
  name,
  placeholder,
  options,
  disabled,
  value,
  style,
  autoSelectFirstOption = false,
  isMulti,
  bottomAction
}) => {
  const fistOptionSelected = useRef(false);
  const loadOptions = useCallback(
    async (search: string, prevOptions: any[]) => {
      if (options) {
        return { options, hasMore: false };
      }
      if (!url) {
        return { options: [], hasMore: false };
      }
      url = httpClient.prepareUrl(url);
      const urlObj = new URL(url);
      const lastId = prevOptions[prevOptions.length - 1]?.id;
      const lastCreatedAt = prevOptions[prevOptions.length - 1]?.created_at;

      urlObj.searchParams.set("limit", pageSize.toString());
      if (lastId) {
        urlObj.searchParams.append("lastId", lastId);
      }
      if (lastCreatedAt) {
        urlObj.searchParams.append("lastCreatedAt", lastCreatedAt);
      }

      if (search) {
        urlObj.searchParams.set("query", search);
      }

      if (query) {
        Object.keys(query).forEach((key) => {
          urlObj.searchParams.append(key, query[key] as string);
        });
      }

      const res = await httpClient.get<any[]>(urlObj.toString());
      if (!res.data) {
        res.data = [];
      }
      if (autoSelectFirstOption && !fistOptionSelected.current) {
        fistOptionSelected.current = true;
        if (res.data.length > 0) {
          const _value = isMulti ? [res.data[0]] : res.data[0];
          onChange?.(_value);
        }
      }
      return {
        options: res.data,
        hasMore: pageSize === 'all' ? false : res.data.length === pageSize,
      };
    },
    [url, query, options, value, autoSelectFirstOption, onChange, isMulti]
  );
  const isNullOrUndefined = (val: any) => val === null || val === undefined;
  return (
    <Flex vertical gap={0}>
      <AsyncPaginate
        value={isNullOrUndefined(value) ? null : value}
        getOptionLabel={getOptionLabel as any}
        getOptionValue={getOptionValue}
        loadOptions={loadOptions as any}
        onChange={onChange}
        name={name}
        placeholder={placeholder}
        defaultOptions
        isDisabled={disabled}
        styles={style}
        isMulti={isMulti}
      />
      <Flex justify="flex-end">{bottomAction}</Flex>
    </Flex>
  );
};

export default AsyncSelect;
