import { decodeJwt } from "jose";
import { DATE_FORMAT } from "./constants";
import dayjs from "./dayjs";

export function decodeToken<T = any>(token: string) {
  return decodeJwt<T>(token);
}
export function getTokenIfValid(token?: string | null) {
  if (!token) return null;
  const { exp } = decodeToken(token);
  const now = dayjs.utc().unix();
  try {
    return now >= exp ? null : token;
  } catch (e) { }
  return null;
}

// join paths and remove duplicate slashes
export function joinPath(...paths: string[]) {
  const joined = paths.join("/");
  // replace multiple slashes with single slash except http:// or https://
  return joined.replace(/([^:]\/)\/+/g, "$1");
}
export function toCurrencyString(amount: number) {
  return `$${(amount / 100).toFixed(2)}`;
}
export function formatDate(day: any, format = DATE_FORMAT) {
  if (!day) return "";
  try {
    return dayjs(day).format(format);
  } catch (e) { }
  return "";
}

export const isEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const toRgb = (hex: string) => {
  const bigint = parseInt(hex.replace("#", ""), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return { r, g, b };
};

export const getForegroundColor = (color?: string) => {
  if (!color) return "#000";
  const rgb = toRgb(color);
  const brightness = (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000;
  return brightness < 125 ? "#fff" : "#000";
};

export const alphaColor = (color: string, alpha: number) => {
  const rgb = toRgb(color);
  return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${alpha})`;
};
