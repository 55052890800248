import { Prisma, RoadClosure } from ".prisma/client";
import EditOutlined from "@ant-design/icons/EditOutlined";
import PlusOutlined from "@ant-design/icons/PlusOutlined";
import { Alert, Button, Form, Input, Modal, Space, Spin, message } from "antd";
import { ColumnType } from "antd/es/table";
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import { formatDate, joinPath } from "../../common/utility";
import usePaginator from "../hooks/use-paginator";
import httpClient from "../libs/http-client";
import { paginator } from "../libs/paginator";
import Editor from "./canvas/editor";
import ObjectType from "./canvas/object-type";
import CompleteBtn from "./complete-btn";
import DeleteBtn from "./delete-btn";
import InfiniteTable from "./infinite-table";
import InputDate from "./input-date";

type Project = Prisma.ProjectGetPayload<{
  include: {
    area: true;
  }
}>
interface RoadClosureListProps {
  project: Project;
}

const RoadClosureList: FunctionComponent<RoadClosureListProps> = ({ project }) => {
  const [model, setModel] = useState<RoadClosure>();
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const { load, loading, data, done, reset, updateData } =
    usePaginator<RoadClosure>();

  const save = useCallback(
    async (values: any) => {
      setSaving(true);
      try {
        const url = model?.id
          ? joinPath("/road-closures", model.id)
          : "/road-closures";
        const method = model?.id
          ? httpClient.put.bind(httpClient)
          : httpClient.post.bind(httpClient);
        const isUpdate = model?.id ? true : false;
        const response = await method<RoadClosure>(url, {
          ...values,
          project_id: project.id,
        });
        if (response.success && response.data) {
          message.success("Road closure saved successfully!");
          setModel(undefined);
          if (isUpdate) {
            updateData((list) =>
              list.map((item) =>
                item.id === response?.data?.id ? ({
                  ...item,
                  ...response.data,
                }) : item
              )
            );
          } else {
            updateData((list) => [response.data as any, ...list]);
          }
        }
      } catch (error: any) {
        message.error(error.message ?? "Failed to update for unknown reason!");
      }
      setSaving(false);
    },
    [project, model, updateData]
  );

  const reload = useCallback(
    (query?: string) => {
      const request = paginator<RoadClosure>(
        "road-closures",
        { query, project_id: project.id } as any,
        50
      );
      reset(request);
    },
    [project.id]
  );

  useEffect(() => {
    reload();
  }, [reload]);

  const columns = useMemo(() => {
    return [
      {
        key: "name",
        title: "Name",
        width: '200px',
        render(value, row, index) {
          return row.name;
        },
      },
      {
        key: "start_date",
        title: "Start Date",
        width: '200px',
        render(value, row) {
          return formatDate(row.start_date, "MM-DD-YYYY");
        },
      },
      {
        key: "end_date",
        title: "End Date",
        width: '200px',
        render(value, row) {
          return formatDate(row.end_date, "MM-DD-YYYY");
        },
      },
      {
        key: 'is_complete',
        title: 'Complete',
        width: '200px',
        render(value, row) {
          return row.is_complete ? 'Yes' : 'No';
        }
      },
      {
        key: "actions",
        title: "#",
        width: '200px',
        render(value, row) {
          return (
            <Space>
              <DeleteBtn
                key="delete"
                apiURL={joinPath("/road-closures", row.id)}
                onDelete={() => {
                  updateData((list) =>
                    list.filter((item) => item.id !== row.id)
                  );
                }}
              />
              <Button
                key="edit"
                type="primary"
                onClick={() => {
                  setModel(row);
                }}
                icon={<EditOutlined />}
              />
              <CompleteBtn
                key="complete"
                baseUrl={joinPath("/road-closures", row.id, "complete")}
                isComplete={row.is_complete}
                onUpdate={(data) => {
                  updateData((list) => list.map((item) => {
                    if (item.id === row.id) {
                      return {
                        ...item,
                        ...data
                      }
                    }
                    return item;
                  }));
                }}
              />
            </Space>
          );
        },
      },
    ] as ColumnType<RoadClosure>[];
  }, []);
  return (
    <>
      <InfiniteTable
        loading={loading}
        columns={columns}
        hasMore={!done}
        loadMore={load}
        id={"id"}
        key={"id"}
        rowKey={"id"}
        pagination={false}
        dataSource={data}
        title="Road Closures"
        onSearch={reload}

        headerActions={
          <Button
            htmlType="button"
            type="primary"
            onClick={() => {
              setModel({} as any);
            }}
            icon={<PlusOutlined />}
          >
            Add
          </Button>
        }
      />
      <Modal
        title={model?.id ? "Edit Road Closure" : "Add Road Closure"}
        open={!!model}
        onCancel={() => setModel(undefined)}
        onOk={save}
        footer={null}
        destroyOnClose
        width={'90%'}
        style={{ maxWidth: `calc(1200px - 5%)` }}
      >
        {model && (
          <Form layout="vertical" initialValues={model} onFinish={save}>
            {error && (
              <Form.Item label=" ">
                <Alert type="error" message={error} />
              </Form.Item>
            )}
            <Form.Item>
              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: "Name is required" }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Id Number"
                name="id_number"
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="start_date"
                label="Start Date"
                rules={[{ required: true, message: "Start Date is required" }]}
              >
                <InputDate />
              </Form.Item>
              <Form.Item
                name="end_date"
                label="End Date"
                rules={[{ required: true, message: "End Date is required" }]}
              >
                <InputDate />
              </Form.Item>
              <Form.Item
                label="Location"
                name="shape"
                rules={[
                  {
                    required: true,
                    message: "Please identify road closure location",
                  },
                ]}
              >
                <Editor
                  height={project.area?.map_height}
                  width={project.area?.map_width}
                  src={project.area?.project_map}
                  objectType={ObjectType.RoadClosure}
                  objectId={model?.id}

                />
              </Form.Item>
              <Form.Item label=" ">
                <Spin spinning={saving}>
                  <Button type="primary" htmlType="submit">
                    Save
                  </Button>
                </Spin>
              </Form.Item>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </>
  );
};

export default RoadClosureList;
