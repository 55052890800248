import { User } from ".prisma/client";
import { ColumnType } from "antd/es/table";
import { FunctionComponent, useCallback, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import replaceParams from "../../../common/replace-params";
import { formatDate, joinPath } from "../../../common/utility";
import DeleteBtn from "../../components/delete-btn";
import InfiniteTable from "../../components/infinite-table";
import ToggleSwitch from "../../components/toggle-switch";
import usePaginator from "../../hooks/use-paginator";
import { paginator } from "../../libs/paginator";

interface UserListPageProps { }

const UserListPage: FunctionComponent<UserListPageProps> = () => {
  const { load, loading, data, done, reset, updateData } = usePaginator<User>();

  const reload = useCallback((query?: string) => {
    const request = paginator<User>("users", { query } as any, 50);
    reset(request);
  }, []);

  useEffect(() => {
    reload();
  }, []);

  const columns = useMemo(() => {
    return [
      {
        key: "name",
        title: "Name",
        render(value, row, index) {
          return (
            <Link to={replaceParams("/users/:id", { id: row.id })}>
              {row.first_name} {row.last_name}
            </Link>
          );
        },
      },
      {
        key: "email",
        title: "Email",
        render(value, row, index) {
          return row.email;
        },
      },
      {
        key: "active",
        title: "Active",
        width: 100,
        render(value, row, index) {
          return <ToggleSwitch
            checkedLabel="Yes"
            uncheckedLabel="No"
            isChecked={row.active}
            onChange={(checked) => {
              updateData((list) =>
                list.map((item) =>
                  item.id === row.id ? { ...item, active: checked } : item
                )
              );
            }}
            putUrl={(active) => joinPath("/users", row.id, "set-active", `${active}`)}
          />
        },
      },
      {
        key: "is_super_admin",
        title: "S. Admin",
        width: 100,

        render(value, row, index) {
          return <ToggleSwitch
            checkedLabel="Yes"
            uncheckedLabel="No"
            isChecked={row.is_super_admin}
            onChange={(checked) => {
              updateData((list) =>
                list.map((item) =>
                  item.id === row.id ? { ...item, is_super_admin: checked } : item
                )
              );
            }}
            putUrl={(active) => joinPath("/users", row.id, "set-super-admin", `${active}`)}
          />
        },
      },
      {
        key: "created_at",
        title: "Created At",
        render(value, row) {
          return formatDate(row.created_at, "LLL");
        },
      },
      {
        key: "actions",
        title: "#",
        width: 50,
        render(value, row) {
          return <DeleteBtn
            key="delete"
            apiURL={joinPath("/users", row.id)}
            onDelete={() => {
              updateData((list) =>
                list.filter((item) => item.id !== row.id)
              );
            }}
          />
        },
      },
    ] as ColumnType<User>[];
  }, []);
  return (
    <InfiniteTable
      loading={loading}
      columns={columns}
      hasMore={!done}
      loadMore={load}
      id={"id"}
      key={"id"}
      rowKey={"id"}
      pagination={false}
      dataSource={data}
      title="Users"
      onSearch={reload}
    />
  );
};

export default UserListPage;
