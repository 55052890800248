import { EmailTemplate } from ".prisma/client";
import { message } from "antd";
import { FunctionComponent, useState } from "react";
import { useNavigate } from "react-router-dom";
import EmailTemplateForm from "../../components/email-template-form";
import { updateEmailTemplateCache } from "../../libs/get-email-templates";
import httpClient from "../../libs/http-client";

interface EmailTemplateCreatePageProps { }

const EmailTemplateCreatePage: FunctionComponent<EmailTemplateCreatePageProps> = () => {
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const navigate = useNavigate();
  const onFinish = async (values: any) => {
    setSaving(true);
    try {
      const response = await httpClient.post<EmailTemplate>("/email-templates", values);
      if (response.success && response.data) {
        updateEmailTemplateCache(response.data);
        message.success("Email template created successfully!");
        navigate(`/email-templates/${response.data.id}`);
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      setError(error.message || "Something went wrong!");
    }
    setSaving(false);
  };
  return (
    <EmailTemplateForm
      title="Create Email Template"
      additionalButtons={[]}
      onChange={onFinish}
      error={error}
      busy={saving}
    />
  );
};

export default EmailTemplateCreatePage;
