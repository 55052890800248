import Color from 'color';
import { Line, Wedge } from "react-konva";
import ShapeType from "./shape-type";

import Konva from "konva";
import { useCallback, useRef } from "react";
import Point from "./point";
import ShapeData from "./shape-data";
export type ShapeRenderProps = {
    data: ShapeData;
    color: string;
    highlighted?: boolean;
    onClick?: (e: Konva.KonvaEventObject<MouseEvent>) => void;
    onDoubleClick?: (e: Konva.KonvaEventObject<MouseEvent>) => void;
    onDrag?: (e: Konva.KonvaEventObject<DragEvent>) => void;
    zoomRatio?: number;
    onMouseOver?: (e: Konva.KonvaEventObject<MouseEvent>) => void;
    onMouseOut?: (e: Konva.KonvaEventObject<MouseEvent>) => void;
    showLabel?: boolean;
    center: Point;
};


export function ShapeRender({
    data, highlighted, onClick, onDrag, zoomRatio = 1, color,
    onMouseOut, onMouseOver,
    onDoubleClick,
    showLabel = false,
}: ShapeRenderProps) {
    const lastClick = useRef<number>();
    let doubleClickWindow = (Konva as any)?.doubleClickWindow ?? 400;
    const handleClick = useCallback((e: Konva.KonvaEventObject<MouseEvent>) => {
        const now = Date.now();
        if (lastClick.current && now - lastClick.current < doubleClickWindow) {
            return;
        }
        lastClick.current = now;
        onClick?.(e);
    }, [onClick]);

    const handleDoubleClick = useCallback((e: Konva.KonvaEventObject<MouseEvent>) => {

        onDoubleClick?.(e);
    }, [onDoubleClick]);

    if (data?.type === ShapeType.Polygon && !data.points.length) {
        return null;
    }
    if (data?.type === ShapeType.Pin && !data.position) {
        return null;
    }
    const _color = Color(color);

    if (data?.type === ShapeType.Polygon) {
        return <Line
            key={data.id}
            points={data.points.flatMap((p) => [
                p.x * zoomRatio,
                p.y * zoomRatio,
            ])}
            x={(data?.position?.x ?? 0) * zoomRatio}
            y={(data?.position?.y ?? 0) * zoomRatio}
            strokeWidth={5}
            stroke={_color.lighten(highlighted ? 0 : 0.2).hex()}
            closed={true}
            onClick={handleClick}
            onTap={handleClick}
            onDblClick={handleDoubleClick}
            onDblTap={handleDoubleClick}
            onMouseOver={e => {
                onMouseOver?.(e);
            }}
            onMouseOut={e => {
                onMouseOut?.(e);
            }}
            draggable={typeof onDrag === "function"}
            onDragEnd={onDrag} />
    }

    return <Wedge
        key={data.id}
        x={(data?.position?.x ?? 0) * zoomRatio}
        y={(data?.position?.y ?? 0) * zoomRatio}
        radius={30}
        angle={60}
        rotation={-120}
        stroke={_color.lighten(highlighted ? 0 : 0.2).hex()}
        strokeWidth={5}
        onClick={handleClick}
        onTap={handleClick}
        onDblClick={handleDoubleClick}
        onDblTap={handleDoubleClick}
        onMouseOver={e => {
            onMouseOver?.(e);
        }}
        onMouseOut={e => {
            onMouseOut?.(e);
        }}
        draggable={typeof onDrag === "function"}
        onDragEnd={onDrag}
    />
}
