import { ProjectArea } from ".prisma/client";
import { message } from "antd";
import { FunctionComponent, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProjectAreaForm from "../../components/project-area-form";
import httpClient from "../../libs/http-client";

interface ProjectAreaCreatePageProps { }

const ProjectAreaCreatePage: FunctionComponent<
  ProjectAreaCreatePageProps
> = () => {
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const navigate = useNavigate();
  const onFinish = async (values: any) => {
    setSaving(true);
    try {
      const response = await httpClient.post<ProjectArea>(
        "/project-areas",
        values
      );
      if (response.success && response.data) {
        message.success("Project area created successfully!");
        navigate(`/project-areas/${response.data.id}`);
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      message.error(error.message ?? "Failed to create for unknown reason!");

    }
    setSaving(false);
  };
  return (
    <ProjectAreaForm
      title="Create Project Area"
      additionalButtons={[]}
      onChange={onFinish}
      error={error}
      busy={saving}
    />
  );
};

export default ProjectAreaCreatePage;
