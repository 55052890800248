import { User } from ".prisma/client";
import {
  Alert,
  Button,
  Form,
  Input,
  Space,
  Spin,
  Switch,
  Typography
} from "antd";
import { FunctionComponent } from "react";
import { Link } from "react-router-dom";
import { isEmail } from "../../common/utility";
import useUser from "../hooks/use-user";
import AsyncSelect from "./async-select";


interface UserFormProps {
  value?: Partial<User>;
  onChange?: (value: Partial<User>) => void;
  busy?: boolean;
  error?: string;
  title: string;
  additionalButtons?: React.ReactNode;
}


const UserForm: FunctionComponent<UserFormProps> = ({
  value,
  onChange,
  busy,
  error,
  title,
  additionalButtons,
}) => {
  const user = useUser();

  return (
    <Form
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 14 }}
      layout="horizontal"
      onFinish={onChange}
      initialValues={value}
      colon={false}
    >
      <Form.Item label=" ">
        <Typography.Title level={3}>{title}</Typography.Title>
      </Form.Item>
      {error && (
        <Form.Item label=" ">
          <Alert type="error" message={error} />
        </Form.Item>
      )}
      <Form.Item
        label="First Name"
        name="first_name"
        rules={[{ required: true, message: "Please input first name!" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Last Name"
        name="last_name"
        rules={[{ required: true, message: "Please input last name!" }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="Email"
        name="email"
        rules={[
          {
            required: true,
            message: "Please input email!",
            validator: async (rule, value) => {
              if (!isEmail(value)) {
                throw new Error("Email is not valid!");
              }
            },
          },
        ]}
      >
        <Input disabled={!user.isSuperAdmin} />
      </Form.Item>

      <Form.Item
        label="Password"
        name="password"
        rules={[{ required: !value?.id, message: "Please input password!" }]}
      >
        <Input.Password />
      </Form.Item>

      {user.isSuperAdmin && (
        <Form.Item
          label="Is Super Admin"
          name="is_super_admin"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
      )}

      {user.isSuperAdmin && (
        <Form.Item label="Is Active" name="active" valuePropName="checked">
          <Switch />
        </Form.Item>
      )}
      <Form.Item
        label="Groups"
        name="groups"
      >
        <AsyncSelect
          getOptionLabel={(option) => <Link to={`/user-groups/${option.id}`}>{option.name}</Link> as any}
          getOptionValue={(option) => option.id}
          url={`/user-groups`}
          style={{
            menu: (provided: any) => ({
              ...provided,
              zIndex: 9999,
            }),
          } as any}
          isMulti
          bottomAction={<Link to="/user-groups/create">Create group</Link>}
        />
      </Form.Item>
      <Form.Item
        label="Admin Of Project Areas"
        name="admin_of"
      >
        <AsyncSelect
          getOptionLabel={(option) => <Link to={`/project-areas/${option.id}`}>{option.name}</Link> as any}
          getOptionValue={(option) => option.id}
          url={`/project-areas`}
          style={{
            menu: (provided: any) => ({
              ...provided,
              zIndex: 9999,
            }),
          } as any}
          isMulti
          bottomAction={<Link to="/project-areas/create">Create Project Area</Link>}
        />
      </Form.Item>
      <Form.Item label=" ">
        <Space>
          <Spin spinning={busy}>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </Spin>
          {additionalButtons}
        </Space>
      </Form.Item>
    </Form>
  );
};

export default UserForm;
