import { Space } from "antd";
import { FunctionComponent } from "react";
import AppConfigList from "../components/app-config-list";

interface AppConfigPageProps { }

const AppConfigPage: FunctionComponent<AppConfigPageProps> = () => {
  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <AppConfigList />
    </Space>
  );
};

export default AppConfigPage;
