import { type EmailTemplate } from "@prisma/client";
import httpClient from "./http-client";

let fetchedTemplates: EmailTemplate[] = [];
let cached = false;
let caching = false;
let lastUpdated = new Date();

let resolvers: ((templates: EmailTemplate[]) => void)[] = [];

const fetchTemplates = async () => {
    caching = true;
    try {
        const templates = await httpClient.get<EmailTemplate[]>("/email-templates");
        fetchedTemplates = templates.data || [];
        cached = true;
        lastUpdated = new Date();
        resolvers.forEach((resolve) => resolve(fetchedTemplates));
    } catch { }
    caching = false;
};

type Callback = (templates: EmailTemplate[]) => EmailTemplate[];

export const updateEmailTemplateCache = (template: Callback | EmailTemplate | EmailTemplate[]) => {
    if (typeof template === "function") {
        fetchedTemplates = template(fetchedTemplates);
    } else if (Array.isArray(template)) {
        fetchedTemplates = template;
    } else {
        const index = fetchedTemplates.findIndex((t) => t.id === template.id);
        if (index > -1) {
            fetchedTemplates[index] = template;
        } else {
            fetchedTemplates.push(template);
        }
    }
}

export const getEmailTemplates = async () => {
    if (lastUpdated && new Date().getTime() - lastUpdated.getTime() > 1000 * 60 * 5) { // 5 minutes
        cached = false;
    }
    if (!cached && !caching) {
        fetchTemplates();
    }
    if (cached) {
        return fetchedTemplates;
    }
    return new Promise<EmailTemplate[]>((resolve) => {
        resolvers.push(resolve);
    });
};

export const getEmailTemplate = async (id: string) => {
    const templates = await getEmailTemplates();
    return templates.find((template) => template.id === id);
};