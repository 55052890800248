import { Button, Input, Space, Spin, Typography } from "antd";
import Table, { TableProps } from "antd/es/table";
import { Breakpoint } from "antd/es/_util/responsiveObserve";
import { FunctionComponent } from "react";
import Center from "./center";

export interface InfiniteTableProps<T = any>
  extends Omit<TableProps<T>, "title"> {
  loadMore: () => any;
  hasMore: boolean;
  title: string;
  headerActions?: React.ReactNode;
  onSearch?: (value?: string) => any;
}

const InfiniteTable: FunctionComponent<InfiniteTableProps> = ({
  hasMore,
  loadMore,
  loading,
  onSearch,
  title,
  headerActions,
  ...props
}) => {
  return (
    <Space direction="vertical">
      {
        !!headerActions && <Space
          style={{
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography.Title level={3}>{title}</Typography.Title>
          {headerActions}
        </Space>
      }
      {
        typeof onSearch === 'function' && <Input.Search allowClear placeholder="Search" onSearch={onSearch} />
      }
      <Table
        {...props}
        columns={props.columns?.map((e, i, arr) => {
          let responsive: Breakpoint[] | undefined = ["md"];
          if (i === 0 || i === arr.length - 1) {
            responsive = undefined;
          }
          return {
            ...e,
            ellipsis: e.key !== "actions",
            responsive,
            width: e.key === "actions" ? 100 : e.width,
          };
        })}
        loading={loading}
        footer={() => {
          if (hasMore) {
            return (
              <Center>
                <Spin spinning={!!loading}>
                  <Button
                    type="primary"
                    onClick={loadMore}
                    disabled={!!loading}
                  >
                    Load more
                  </Button>
                </Spin>
              </Center>
            );
          }
          return null;
        }}
      />
    </Space>
  );
};

export default InfiniteTable;
