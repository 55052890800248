import { useCallback } from "react";
import { useAppContext } from "../context/app-context";

export default function useUser() {
  const { user, logout } = useAppContext();
  const isSuperAdmin = user?.is_super_admin;
  const isSignedIn = !!user;
  const adminOf = useCallback((projectAreaId: string) => !!user?.admin_of.includes(projectAreaId), [user]);
  const memberOf = useCallback((projectAreaId: string) => !!user?.project_areas.includes(projectAreaId), [user]);
  return { adminOf, memberOf, isSuperAdmin, user, isSignedIn, logout };
}
