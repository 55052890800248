import { FunctionComponent } from "react";
import {
  createBrowserRouter,
  Navigate,
  RouteObject,
  RouterProvider
} from "react-router-dom";
import AppLayout from "./app-layout";
import AppConfigPage from "./pages/app-config-page";
import DashboardPage from "./pages/dashboard";
import EmailTemplateCreatePage from "./pages/email-templates/email-template-create";
import EmailTemplateListPage from "./pages/email-templates/email-template-list";
import EmailTemplateUpdatePage from "./pages/email-templates/email-template-update";
import ErrorPage from "./pages/error";
import ProfilePage from "./pages/profile/profile";
import ProjectAreaCreatePage from "./pages/project-areas/project-area-create";
import ProjectAreaListPage from "./pages/project-areas/project-area-list";
import ProjectAreaUpdatePage from "./pages/project-areas/project-area-update";
import ProjectCreatePage from "./pages/projects/project-create";
import ProjectListPage from "./pages/projects/project-list";
import ProjectUpdatePage from "./pages/projects/project-update";
import SignInPage from "./pages/sign-in/sign-in";
import UserGroupCreatePage from "./pages/user-groups/user-group-create";
import UserGroupListPage from "./pages/user-groups/user-group-list";
import UserGroupUpdatePage from "./pages/user-groups/user-group-update";
import UserCreatePage from "./pages/users/user-create";
import UserListPage from "./pages/users/user-list";
import UserUpdatePage from "./pages/users/user-update";
import ProtectedPage from "./protected-page";


interface AppRouterProps { }

export const routes: RouteObject[] = [
  {
    path: "/",
    element: <AppLayout />,
    children: [
      { path: "/", element: <Navigate to="/dashboard" />, index: true },
      {
        path: "/sign-in", element: <SignInPage />
      },
      {
        path: "/dashboard",
        element: (
          <ProtectedPage>

            <DashboardPage />

          </ProtectedPage>
        ),
      },
      {
        path: "/profile",
        element: (
          <ProtectedPage>

            <ProfilePage />

          </ProtectedPage>
        ),
      },
      {
        path: "/project-areas",
        children: [
          {
            path: "create",
            element: (
              <ProtectedPage superAdminOnly>

                <ProjectAreaCreatePage />

              </ProtectedPage>
            ),
          },
          {
            path: ":id",
            element: (
              <ProtectedPage superAdminOnly>

                <ProjectAreaUpdatePage />

              </ProtectedPage>
            ),
          },
          {
            path: "list",
            element: (
              <ProtectedPage>

                <ProjectAreaListPage />

              </ProtectedPage>
            ),
          },
          {
            path: "",
            element: <Navigate to={"list"} />, // <Navigate to="/project-areas/list" />,
            index: true,
          },
        ],
      },
      {
        path: "/projects",
        children: [
          {
            path: "create",
            element: (
              <ProtectedPage>

                <ProjectCreatePage />

              </ProtectedPage>
            ),
          },
          {
            path: ":id",
            element: (
              <ProtectedPage>

                <ProjectUpdatePage />

              </ProtectedPage>
            ),
          },
          {
            path: "list",
            element: (
              <ProtectedPage>

                <ProjectListPage />

              </ProtectedPage>
            ),
          },
          {
            path: "",
            element: <Navigate to={"list"} />, // <Navigate to="/projects/list" />,
            index: true,
          },
        ],
      },
      {
        path: "/users",
        children: [
          {
            path: "create",
            element: (
              <ProtectedPage>

                <UserCreatePage />

              </ProtectedPage>
            ),
          },
          {
            path: ":id",
            element: (
              <ProtectedPage>

                <UserUpdatePage />

              </ProtectedPage>
            ),
          },
          {
            path: "list",
            element: (
              <ProtectedPage>

                <UserListPage />

              </ProtectedPage>
            ),
          },
          {
            path: "",
            element: <Navigate to={"list"} />, // <Navigate to="/users/list" />,
            index: true,
          },
        ],
      },
      {
        path: "/user-groups",
        children: [
          {
            path: "create",
            element: <ProtectedPage superAdminOnly>

              <UserGroupCreatePage />

            </ProtectedPage>
          },
          {
            path: ":id",
            element: <ProtectedPage superAdminOnly>

              <UserGroupUpdatePage />

            </ProtectedPage>
          },
          {
            path: "list",
            element: <ProtectedPage superAdminOnly>

              <UserGroupListPage />

            </ProtectedPage>
          },
          {
            path: "",
            element: <Navigate to={"list"} />
          }
        ]
      },
      {
        path: "/email-templates",
        children: [
          {
            path: "create",
            element: <ProtectedPage superAdminOnly>

              <EmailTemplateCreatePage />

            </ProtectedPage>
          },
          {
            path: ":id",
            element: <ProtectedPage superAdminOnly>

              <EmailTemplateUpdatePage />

            </ProtectedPage>
          },
          {
            path: "list",
            element: <ProtectedPage superAdminOnly>

              <EmailTemplateListPage />

            </ProtectedPage>
          },
          {
            path: "",
            element: <Navigate to={"list"} />
          }
        ]
      },
      {
        path: "/app-configs",
        children: [
          {
            path: "",
            element: <ProtectedPage superAdminOnly>

              <AppConfigPage />

            </ProtectedPage>
          }
        ]
      },
      {
        path: "/error",
        element: <ErrorPage />,
      },
      {
        path: "*",
        element: (
          <Navigate
            to={{
              pathname: "/error",
              search: "?code=404&message=Page not found",
            }}
          />
        ),
      },
    ],
  },
];

export const Router = createBrowserRouter(routes);

const AppRouter: FunctionComponent<AppRouterProps> = () => {
  return <RouterProvider router={Router} />;
};

export default AppRouter;
