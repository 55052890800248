import { User } from ".prisma/client";
import { message } from "antd";
import { FunctionComponent, useState } from "react";
import { useNavigate } from "react-router-dom";
import UserForm from "../../components/user-form";
import httpClient from "../../libs/http-client";

interface UserCreatePageProps { }

const UserCreatePage: FunctionComponent<UserCreatePageProps> = () => {
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const navigate = useNavigate();
  const onFinish = async (values: any) => {
    setSaving(true);
    try {
      const response = await httpClient.post<User>("/users", values);
      if (response.success && response.data) {
        message.success("User created successfully!");
        navigate(`/users/${response.data.id}`);
      } else {
        throw new Error(response.message);
      }
    } catch (error: any) {
      setError(error.message || "Something went wrong!");
    }
    setSaving(false);
  };
  return (
    <UserForm
      title="Create User"
      additionalButtons={[]}
      onChange={onFinish}
      error={error}
      busy={saving}
    />
  );
};

export default UserCreatePage;
