.form {
    min-width: 320px;
    padding: 20px;
    border-radius: 15px;

    h2 {
        text-align: center;
    }

    button[class*="ant-btn-link"] {
        float: right;
    }
}